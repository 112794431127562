import React from 'react';
import Product from '../product/m-product';

const  OutstandingProducts = (props) => {
  const products = props.products.map(
    product => <Product
      key={product.id}
      product={product} isOutstanding />);
  return (
    <section className="mw mt5 outstanding cf">
      {products}
    </section>
  );
};

export default OutstandingProducts;