import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './m-footer-BKP.scss';
import NavSocialFooter from './social/c-social-footer';
import './m-footer-BKP.scss';

class Footer extends Component {
  render() {
    return (
      <footer className="footer mt5 cf">
        <NavSocialFooter view="sm-social" />
        <p>&copy; Sunstar Americas, Inc. 2020</p>
        <Link to="/terms" title="Términos y Condiciones">Términos y Condiciones</Link>
      </footer>
    );
  }
}

export default Footer;