import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import './../stylesheets/App.scss';
import Home from '../containers/home/home';
import Product from '../containers/product/product';
import Article from '../containers/article/article';
import Terms from './moduleTerms/m-terms';
import axios from '../utils/axios';
import * as actions from '../store/actions/index';

class App extends Component {
  componentDidMount() {
    const token = sessionStorage.getItem('token');
    if(token) {
      axios.post('/jwt-auth/v1/token/validate', {}, {
        headers: {'Authorization': `Bearer ${token}`}
      })
      .then(res => {
        const uName = sessionStorage.getItem('uName');
        this.props.onLoginSuccess(token, uName);
      }).catch(err => {
        this.props.onLogout();
      });
    }
  }

  render() {
    return (
      <div className="app">
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/productos/:id/:slug' component={Product} />
          <Route path='/articulos/:id/:slug' component={Article} />
          <Route path='/terms' component={Terms} />
          <Route path='/productos' render={(props) => <Home {...props} type='products' />} />
          <Route path='/tag/:slugTag' render={(props) => <Home {...props} isTag />} />
          <Route path='/categoria/:slugCategory/:slugTag' render={(props) => <Home {...props} isCategory isTag />} />
          <Route path='/categoria/:slugCategory' render={(props) => <Home {...props} isCategory />} />
          <Redirect to='/' />
        </Switch>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logout()),
    onLoginSuccess: (token, name) => dispatch(actions.loginSuccess(token, name))
  };
};

export default connect(null, mapDispatchToProps)(App);