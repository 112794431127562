import React from 'react';

import CommentsList from './commentsList/m-comments-list';
import CommentsForm from './commentsForm/m-comments-form';
import Spinner from '../ui/spinner/spinner';
import './m-comments.scss';

const Comments = (props) => {
  if(props.error) {
    return <p>{props.error.message}</p>;
  }

  let commentsList = <CommentsList comments={props.comments} />;
  if(props.loading) {
    commentsList = <Spinner />;
  }

  return (
    <section className="comments">
      <h2>Comentarios</h2>
      {commentsList}
      <CommentsForm id={props.id} />
    </section>
  );
};

export default Comments;