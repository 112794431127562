import React, { Component } from 'react';
import { connect } from 'react-redux';

import ArticleContent from '../../components/moduleArticleContent/m-article-content';
import ArticleHeader from '../../components/moduleArticleHeader/m-article-header';
import RelatedArticles from '../../components/moduleRelatedArticles/m-related-articles';
import * as actions from '../../store/actions/index';
import Layout from '../../components/layout/layout';
import Spinner from '../../components/ui/spinner/spinner';
import * as utils from '../../utils/utilities';

class Article extends Component {
  state = {
    isMobile: false,
    breakpointCols: utils.breakpointsColumns.LG
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
    this.loadPost(id);
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    if(id !== prevProps.match.params.id) {
      this.loadPost(id);
    }

    if(this.props.token && this.props.post) {
      const { tags } = this.props.post.data;
      if(tags) {
        const tagIds = tags.map(tag => tag.id);
        this.props.onSaveRecentTags(JSON.stringify(tagIds), this.props.token);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  loadPost = (id) => {
    this.props.onFetchPost(id);
    this.props.onFetchRelatedProducts(id);
    this.props.onFetchRelatedArticles(id);
    this.props.onFetchComments(id);
  }

  updateDimensions = () => {
    const width = window.innerWidth;
    this.setState({
      isMobile: width < utils.breakpoints.SM,
      breakpointCols: utils.getBreakpointColumns(width)
    });
  }

  render() {
    if(this.props.error) {
      return <p>{this.props.error.message}</p>;
    }
    if(this.props.post) {
      const {id, title, tags, category, featured_media_url, content, post_type, slug} = this.props.post.data;
      const url = `/${utils.postType[post_type]}/${id}/${slug}`;
      return (
        <Layout>
          <div>
            <ArticleHeader
              title={title}
              imageUrl={featured_media_url}
              category={category}
              url={url} />
            <div className="article__wrapper mw cf">
              <ArticleContent
                content={content}
                tags={tags}
                comments={this.props.comments}
                loadingComments={this.props.loadingComments}
                errorComments={this.props.errorComments}
                id={id} />
            </div>
            <RelatedArticles
              title="Productos Relacionados"
              border="bt"
              products={this.props.relatedProducts}
              loading={this.props.loadingRelatedProducts}
              cols={this.state.breakpointCols} />
            <RelatedArticles
              title="Notas Relacionadas"
              border=""
              products={this.props.relatedArticles}
              loading={this.props.loadingRealatedArticles}
              cols={this.state.breakpointCols} />
          </div>
        </Layout>
      );
    }
    return <Spinner />;
  }
}

const mapStateToProps = state => {
  return {
    post: state.post.post,
    relatedProducts: state.post.relatedProducts,
    relatedArticles: state.post.relatedArticles,
    loadingRelatedProducts: state.post.loadingRelatedProducts,
    loadingRealatedArticles: state.post.loadingRealatedArticles,
    token: state.login.token,
    error: state.post.error,
    errorComments: state.post.errorComments,
    comments: state.post.comments,
    loadingComments: state.post.loadingComments
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPost: (id) => dispatch(actions.fetchPost(id)),
    onFetchRelatedProducts: (id) => dispatch(actions.fetchRelatedProducts(id)),
    onFetchRelatedArticles: (id) => dispatch(actions.fetchRelatedArticles(id)),
    onSaveRecentTags: (tags, token) => dispatch(actions.saveRecentTags(tags, token)),
    onFetchComments: (id) => dispatch(actions.fetchArticleComments(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);