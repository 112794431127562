import React, { Component } from 'react';
import axios, { CancelToken } from '../../utils/axios';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import Carrousel from '../../components/moduleCarrousel/m-carrousel';
import OutstandingProducts from '../../components/moduleOutstandingProducts/m-outstanding-products';
import Masonry from '../../components/moduleMasonryProducts/m-masonry';
import InstagramFeed from '../../components/moduleInstagramFeed/m-instagram-feed';
import Spinner from '../../components/ui/spinner/spinner';
import * as utils from '../../utils/utilities';
import * as actions from '../../store/actions/index';
import Layout from '../../components/layout/layout';

const initialState = {
  products: [],
  total_products: 0,
  per_page: 10,
  isLoaded: false,
  loading: false,
  productsOffset: 0,
  articlesOffset: 0,
  offset: 0,
  visible: 0,
  loadMore: false,
  errorMessage: false
};

class Home extends Component {
  signal = CancelToken.source();
  outstandingModuleRef = React.createRef();

  state = initialState;

  componentDidMount() {
    // this.props.onConnect();
    this.props.onLoadBanners();
    this.onLoadProducts();
  }

  componentDidUpdate(prevProps, prevState) {
    if(!this.state.loadMore && this.outstandingModuleRef.current &&
      (!utils.objectIsEmpty(this.props.match.params) || this.props.type)) {
      window.scrollTo(0, this.outstandingModuleRef.current.offsetTop);
    }

    if(!isEqual(this.props.match.params, prevProps.match.params)
      || this.props.type !== prevProps.type
      || this.props.token !== prevProps.token) {
        this.setState(initialState, this.onLoadProducts);
    }
  }

  componentWillUnmount() {
    this.signal.cancel('Load is being canceled');
  }

  onLoadProducts = async (loadMore=false) => {
    try {
      this.setState({loading:true});

      const typeParam = this.props.type ? `&type=${this.props.type}` : '';
      const offsetParam = this.props.type ? `&offset=${this.state.offset}` : '';
      const tagParam = this.props.isTag && this.props.match.params ? `&tag=${this.props.match.params.slugTag}` : '';
      const categoryParam = this.props.isCategory && this.props.match.params ? `&category=${this.props.match.params.slugCategory}` : '';
      const token = this.props.token ? `&token=${this.props.token}` : '';

      const response = await axios.get(`/gum/v1/products`
          +`?per_page=${this.state.per_page}`
          +`&articles_offset=${this.state.articlesOffset}`
          +`&products_offset=${this.state.productsOffset}`
          + typeParam + offsetParam + tagParam + categoryParam + token
        , {
        cancelToken: this.signal.token
      });

      this.setState(prevState => {
        return {
          products: [...prevState.products, ...response.data],
          isLoaded: true,
          loading:false,
          total_products: response.headers['x-wp-total'],
          productsOffset: response.headers['x-wp-products-offset'] || 0,
          articlesOffset: response.headers['x-wp-articles-offset'] || 0,
          per_page: 8,
          visible: prevState.visible + prevState.per_page,
          offset: prevState.offset + prevState.per_page
      }}, this.displayInitialModal);
    } catch (err) {
      this.setState({errorMessage: err.message});
    }
  }

  displayInitialModal = () => {
    if(this.props.location.search
      && utils.getUrlParameters(this.props.location.search).p === 'reset') {
        this.props.onDisplayRestorePassword(utils.getUrlParameters(this.props.location.search).token);
    } else if(!this.props.modalShowed) {
        // this.props.onDisplayNewsletter();
    }
  }

  loadMore = (e) => {
    e.preventDefault();
    this.setState({loadMore: true}, this.onLoadProducts);
  }

  render() {
    if(this.state.errorMessage) {
      return <p>{this.state.errorMessage}</p>;
    }

    if(this.state.isLoaded) {
      return (
        <Layout>
          <Carrousel
            shopClicked={this.props.onShopClicked}
            banners={this.props.banners}
            isLoaded={this.props.isLoaded}
            loaded={this.displayInitialModal} />
          <div ref={this.outstandingModuleRef}></div>
          <OutstandingProducts
            products={this.state.products.slice(0,2)} />
          <Masonry
            products={this.state.products.slice(2)}
            total={this.state.total_products}
            tagSelected={this.props.tagSelected}
            productsOffset={this.state.productsOffset}
            articlesOffset={this.state.articlesOffset}
            onLoadMore={this.loadMore}
            isLoading={this.state.loading}
            showLoadMore={this.state.visible < this.state.total_products}/>
          <InstagramFeed />
        </Layout>
      );
    }
    return <Spinner />;
  }
}

const mapStateToProps = state => {
  return {
    modalShowed: state.modal.suscriptionShowed,
    banners: state.posts.banners,
    isLoaded: state.posts.isLoaded,
    token: state.login.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDisplayNewsletter: () => dispatch(actions.toggleSubscription()),
    onDisplayRestorePassword: (token) => dispatch(actions.resetPassword(token)),
    onLoadBanners: () => dispatch(actions.fetchBanners()),
    onShopClicked: e => dispatch(actions.toggleRetails(e)),
    onConnect: () => dispatch(actions.connect())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);