import React, { PureComponent } from 'react';

import './m-product.scss';
import ProductShopButton from './productShopButton/c-shop-button';
import ShareButton from './productShareButton/c-share-button';
import ProductLabel from './productLabel/c-label';
import ProductImg from './productImg/c-img';
import ProductTitle from './productTitle/c-title';
import ProductCopy from './productCopy/c-copy';
import ProductTags from './productTags/c-tags';
import * as utils from '../../utils/utilities';

class Product extends PureComponent {

  render() {
    const {id, title, excerpt, slug, category, tags, acf, post_type} = this.props.product;
    const itemClass = this.props.isOutstanding ? 'outstanding product cf' : 'product cf';
    const outstanding_image = acf.outstanding_image ? acf.outstanding_image.url : '';
    const outstanding_image_wide = acf.outstanding_image_wide ? acf.outstanding_image_wide.url : '';
    const preview_image = acf.preview_image ? acf.preview_image.url : '';
    const imageUrl = this.props.isOutstanding
      ? {img: outstanding_image, imgWide: outstanding_image_wide} : preview_image;
    const shopButton = post_type === 'products'
      ? <ProductShopButton hasLabel={ this.props.isOutstanding } /> : null;

    const url = `/${utils.postType[post_type]}/${id}/${slug}`;

    return (
      <article className={ itemClass }>
        <ProductLabel name={ category } />
        <ProductImg
          imgUrl={ imageUrl }
          id={ id }
          url = { url }
          isOutstanding= { this.props.isOutstanding }
          title={ title.rendered } />
        <div className="product__info">
          <ProductTitle
            id={ id }
            title={ title.rendered }
            isOutstanding= { this.props.isOutstanding }
            url={ url } />
          <ProductCopy copy={ excerpt.rendered } isOutstanding= { this.props.isOutstanding } />
          { shopButton }
        </div>
        <div className="product__footer cf">
          <ProductTags tags={ tags } />
          <ShareButton
            title={ title.rendered }
            url={ url } />
        </div>
      </article>
    );
  }
}

export default Product;
