import React from 'react';

import ShareButton from '../product/productShareButton/c-share-button';
import ProductLabel from '../product/productLabel/c-label';
import './m-article-header.scss';

const articleHeader = (props) => (
  <section className="article mw">
    <h1 className="article__header">{props.title.rendered}</h1>

    <div className="article__img">
      <ProductLabel name={props.category} />
      <img className="product__img" src={props.imageUrl} alt={props.title.rendered}/>
      <ShareButton
        title={props.title.rendered}
        url={props.url} />
    </div>
  </section>
);

export default articleHeader;