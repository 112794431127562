import React from 'react';

import CommentsQualifications from '../moduleCommentsQualifications/m-comments-qualifications';
import './m-product-content.scss';

const ProductContent = (props) => (
  <section className="product__content-data">
    <div dangerouslySetInnerHTML={{__html: props.content.rendered}}></div>
    <CommentsQualifications id={props.id} comments={props.comments} />
  </section>
);

export default ProductContent;
