import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';
import './c-share-button.scss';

class ShareButton extends Component {
  onClickHandler = (event) => {
    let topPosition = event.clientY - 114;
    if(topPosition + 210 >  window.innerHeight) {
      topPosition = window.innerHeight - 210;
    }

    const leftPosition = event.clientX - 250;
    const data = {
      url: encodeURIComponent(window.location.origin + this.props.url),
      leftPosition: leftPosition,
      topPosition: topPosition < 0 ? 0 : topPosition,
      title: this.props.title
    };

    this.props.onShowShareModal(data);
    window.addEventListener('scroll', this.scrollListener);
  }

  scrollListener = (e) => {
    window.removeEventListener('scroll', this.scrollListener);
    this.props.onHideShareModal();
  }

  render() {
    return (
      <div className="tooltip">
        <button className="tooltip__button" title="Compartir" onClick={this.onClickHandler}>Compartir</button>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onShowShareModal: (data) => dispatch(actions.showShareTooltip(data)),
    onHideShareModal: () => dispatch(actions.hideShareTooltip())
  };
};

export default connect(null, mapDispatchToProps)(ShareButton);
