import React from 'react';
import './c-copy.scss';

const Copy = (props) => {
  const classArray = ['product__copy'];

  if(props.isOutstanding ) {
    classArray.push('is-outstanding');
  }

  const copy = props.copy.replace(/<\/?p[^>]*>/g, "");

  return (
    <div className={classArray.join(' ')} ><p>{ copy }</p></div>
  );
};

export default Copy;