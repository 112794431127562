import React from 'react';
import { Link } from 'react-router-dom';
import './c-title.scss';

const Title = (props) => {
  const classArray = ['product__title'];

  if(props.isOutstanding ) {
    classArray.push('is-outstanding');
  }

  return (
    <Link to={props.url} title={props.title} className={classArray.join(' ')} rel="noopener noreferrer">{props.title}</Link>
  );
};

export default Title;