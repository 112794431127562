import * as actionTypes from './actionTypes';

export const showMap = e => {
  e.preventDefault();
  return { type: actionTypes.SHOW_MAP };
};

export const hideMap = () => {
  return { type: actionTypes.HIDE_MAP };
};

export const toggleRetails = e => {
  if(e) {
    e.preventDefault();
  }
  return { type: actionTypes.TOGGLE_RETAIL };
};

export const toggleInitialLogin = e => {
  if(e) {
    e.preventDefault();
  }
  return { type: actionTypes.TOGGLE_INITIAL_LOGIN };
};

export const toggleLogin = e => {
  if(e) {
    e.preventDefault();
  }
  return { type: actionTypes.TOGGLE_LOGIN };
};

export const toggleRegistry = e => {
  if(e) {
    e.preventDefault();
  }
  return { type: actionTypes.TOGGLE_REGISTRY };
};

export const toggleContact = e => {
  if(e) {
    e.preventDefault();
  }
  return { type: actionTypes.TOGGLE_CONTACT };
};

export const toggleContactSuccess = (e, message) => {
  if(e) {
    e.preventDefault();
  }
  return { 
    type: actionTypes.TOGGLE_CONTACT_SUCCESS,
    message: message
  };
};

export const toggleSubscription = () => {
  return { type: actionTypes.TOGGLE_SUBSCRIPTION };
};

export const toggleForgetPassword = e => {
  if(e) {
    e.preventDefault();
  }
  return { type: actionTypes.TOGGLE_FORGET_PASSWORD };
};