import React, { Component } from 'react';
import { connect } from 'react-redux';

import './m-modal-subscription.scss';
import imgSubscription from './subscription-img.png';
import { checkValidity } from '../../utils/formUtilities';
import axios from '../../utils/axios';
import * as actions from '../../store/actions/index';
import Button from '../ui/button/button';

class ModalSubscription extends Component {
  state = {
    inputs: {
      name: {
        elementConfig: {
          type: 'text',
          placeholder: 'Nombre'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },email: {
        elementConfig: {
          type: 'email',
          placeholder: 'Mail'
        },
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      }
    },
    formIsValid: true,
    formTouched: false,
    loading: false
  }

  inputChangeHandler = (event, inputIdentifier) => {
    const updatedInputs = {
      ...this.state.inputs,
      [inputIdentifier]: {
        ...this.state.inputs[inputIdentifier],
        value: event.target.value,
        valid: checkValidity(event.target.value, this.state.inputs[inputIdentifier].validation),
        touched: true
      }
    };

    let formIsValid = this.state.formIsValid;
    if(this.state.formTouched) {
      formIsValid = Object.keys(updatedInputs)
        .reduce((valid, input) => updatedInputs[input].valid && valid, true);
    }

    this.setState({inputs: updatedInputs, formIsValid:formIsValid});
  }

  onSubmitHandler = e => {
    e.preventDefault();

    const formIsValid = Object.keys(this.state.inputs)
      .reduce((valid, input) => this.state.inputs[input].valid && valid, true);

    if(formIsValid) {
      axios.post('/gum/v1/newsletters', {
        name: this.state.inputs.name.value,
        email: this.state.inputs.email.value
      })
      .then(res => {
        this.props.onSuscribe();
        this.setState({loading:false});
      })
      .catch(err => {
        this.props.onSuscribe();
        this.setState({loading:false});
      });
    }
    this.setState({formIsValid: formIsValid, formTouched: true, loading: formIsValid});
  }

  render() {
    const inputObjects = this.state.inputs;
    const inputs = Object.keys(inputObjects)
      .map(input => (
        <input
          key={input}
          {...inputObjects[input].elementConfig}
          className={
            inputObjects[input].touched && !inputObjects[input].valid && this.state.formTouched
              ? 'error' : null}
          value={inputObjects[input].value}
          onChange={e => this.inputChangeHandler(e, input)} />
      ));

    const errorMessage = 'Por favor, completá los campos.';
    const errorBlock = !this.state.formIsValid ? <div className="subscription__error">{errorMessage}</div> : null;

    return (
      <div className="subscription">

        <div className="subscription__img">
          <img src={imgSubscription} alt=""/>
        </div>

        <div className="subscription__info">
          <h2 className="subscription__title">Sumate a la comunidad Gum Argentina</h2>
          <p>Suscribite a nuestro sitio para recibir novedades, información sobre promociones y participar de sorteos.</p>
          <form onSubmit={this.onSubmitHandler} noValidate>
            { inputs }
            { errorBlock }
            <Button config={{className:"subscription__submit"}} loading={this.state.loading}>¡Suscribite ahora!</Button>
          </form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSuscribe: () => dispatch(actions.toggleSubscription())
  };
};

export default connect(null, mapDispatchToProps)(ModalSubscription);