import React from 'react';
import Masonry from 'react-masonry-css';

import './m-masonry.scss';
import Product from '../product/m-product';
import ProductLoadMore from './productLoadMore/c-load-more';
import Spinner from '../ui/spinner/spinner';

const MasonryProducts = (props) => {
  const breakpointColumnsObj = {
    default: 4,
    1365: 4,
    1024: 3,
    768: 2,
    479: 1
  };

  const products = props.products.map(
    product => <Product
      key={product.id}
      product={product} />
  );

  return (
    <section className="mw mt5">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="masonry__grid"
        columnClassName="masonry__column" >
        {products}
      </Masonry>
      {props.isLoading ? <Spinner /> : null}
      {props.showLoadMore ? <ProductLoadMore clicked={props.onLoadMore}/> : null}
    </section>
  );
}

export default MasonryProducts;
