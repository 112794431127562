import React, { Component } from 'react';
import '../m-preheader.scss';

class ToggleButton extends Component {

  render() {
    const classes = ['hamburger', 'hamburger--spin'];
    if(this.props.active) {
      classes.push('is-active');
    }

    return (
      <button className={classes.join(' ')} type="button" onClick={this.props.openMenu}>
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    );
  }
}

export default ToggleButton;