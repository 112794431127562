import React, {Component} from 'react';
import { connect } from 'react-redux';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import './m-modal-initial-login.scss';
import handImg from './hand-gum.png';
import * as actions from '../../store/actions/index';
import Spinner from '../ui/spinner/spinner';

class ModalInitialLogin extends Component {
  responseGoogle = (response) => {
    if(response.tokenId) {
      this.props.onGoogleLogin(response.tokenId);
    }
  }

  responseFacebook = (response) => {
    if(response.accessToken) {
      this.props.onFacebookLogin(response);
    }
  }

  render() {
    let loginButtons = (
      <>
        <FacebookLogin
          appId="1661711687299330"
          callback={this.responseFacebook}
          fields="first_name,email,last_name"
          render={renderProps => (
            <button className="login__facebook" href="/" onClick={renderProps.onClick}>Ingresá con Facebook</button>
          )}
        />
        <GoogleLogin
          clientId="130102562164-4urbj7fipn0dijlv9um3009o0j98b6ue.apps.googleusercontent.com"
          render={renderProps => (
            <a href='/'
              className="login__google"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}>Ingresá con Google</a>
          )}
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}
          cookiePolicy={'single_host_origin'} />
        <a className="login__account" href="/" onClick={this.props.onLogin}>Ingresá con tu cuenta</a>
      </>
    );

    if(this.props.isLoading) {
      loginButtons = <Spinner style={{margin: '95px auto'}} />;
    }

    return (
      <div>
        <div className="login cf">
          <div className="login__img">
            <img src={handImg} alt=""/>
          </div>
          <div className="login__buttons">
            <p className="login__title">Ingresá a GUM</p>
            {loginButtons}
            <p className="login__create-account">o creá tu cuenta <a href="/" onClick={this.props.onRegister}>aquí</a></p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToPros = state => {
  return {
    isLoading: state.login.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegister: e => dispatch(actions.toggleRegistry(e)),
    onLogin: e => dispatch(actions.toggleLogin(e)),
    onGoogleLogin: (idToken) => dispatch(actions.googleLogin(idToken)),
    onFacebookLogin: (payload) => dispatch(actions.facebookLogin(payload))
  };
};

export default connect(mapStateToPros, mapDispatchToProps)(ModalInitialLogin);