import * as actionTypes from './actionTypes';
import axios from '../../utils/axios';

export const fetchPostStart = () => {
  return {
    type: actionTypes.FETCH_POST_START
  };
};

export const fetchPostSuccess = (post) => {
  return {
    type: actionTypes.FETCH_POST_SUCCESS,
    post: post
  };
};

export const fetchPostFail = (error) => {
  return {
    type: actionTypes.FETCH_POST_FAIL,
    error: error
  };
};

export const fetchPost = (id) => {
  return dispatch => {
    dispatch(fetchPostStart());
    axios.get(`gum/v1/products/${id}`)
      .then(res => {
        dispatch(fetchPostSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchPostFail(err));
      });
  };
};

export const fetchRelatedProductsStart = () => {
  return {
    type: actionTypes.FETCH_RELATED_PRODUCTS_START
  };
};

export const fetchRelatedProductsSuccess = (products, totalProducts) => {
  return {
    type: actionTypes.FETCH_RELATED_PRODUCTS_SUCCESS,
    products: products,
    totalProducts: totalProducts
  };
};

export const fetchRelatedProductsFail = (error) => {
  return {
    type: actionTypes.FETCH_RELATED_PRODUCTS_FAIL,
    error: error
  };
};

export const fetchRelatedProducts = (id) => {
  return dispatch => {
    dispatch(fetchRelatedProductsStart());
    axios.get(`gum/v1/related/?type=products&post_id=${id}`)
    .then(res => {
      dispatch(fetchRelatedProductsSuccess(res.data, res.headers['x-wp-total']));
    })
    .catch(err => {
      dispatch(fetchRelatedProductsFail(err));
    });
  };
};


export const fetchRelatedArticlesStart = () => {
  return {
    type: actionTypes.FETCH_RELATED_ARTICLES_START
  };
};

export const fetchRelatedArticlesSuccess = (articles) => {
  return {
    type: actionTypes.FETCH_RELATED_ARTICLES_SUCCESS,
    articles: articles
  };
};

export const fetchRelatedArticlesFail = (error) => {
  return {
    type: actionTypes.FETCH_RELATED_ARTICLES_FAIL,
    error: error
  };
};

export const fetchRelatedArticles = (id) => {
  return dispatch => {
    dispatch(fetchRelatedArticlesStart());
    axios.get(`gum/v1/related/?type=articles&post_id=${id}`)
    .then(res => {
      dispatch(fetchRelatedArticlesSuccess(res.data));
    })
    .catch(err => {
      dispatch(fetchRelatedArticlesFail(err));
    });
  };
};

export const saveRecentTags = (tags, token) => {
  return dispatch => {
    axios.post('gum/v1/account/tags', {tags: tags}, {headers: {
      'Authorization': `Bearer ${token}`
    }});
  };
};

export const fetchArticleCommentsStart = () => {
  return {
    type: actionTypes.FECTH_ARTICLE_COMMENTS_START
  };
};

export const fetchArticleCommentsSuccess = (comments) => {
  return {
    type: actionTypes.FECTH_ARTICLE_COMMENTS_SUCCESS,
    comments: comments
  };
};

export const fetchArticleCommentsFail = (error) => {
  return {
    type: actionTypes.FECTH_ARTICLE_COMMENTS_FAIL,
    error: error
  };
};

export const fetchArticleComments = (id) => {
  return dispatch => {
    dispatch(fetchArticleCommentsStart());
    axios.get(`gum/v1/comment/${id}`)
      .then(res => {
        dispatch(fetchArticleCommentsSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchArticleCommentsFail(err));
      });
  };
} ;

export const fetchProductQualificationsStart = () => {
  return {
    type: actionTypes.FETCH_PRODUCT_QUALIFICATIONS_START
  };
};

export const fetchProductQualificationsSuccess = (comments) => {
  return {
    type: actionTypes.FETCH_PRODUCT_QUALIFICATIONS_SUCCESS,
    comments: comments
  };
};

export const fetchProductQualificationsFail = (error) => {
  return {
    type: actionTypes.FETCH_PRODUCT_QUALIFICATIONS_FAIL,
    error: error
  };
};

export const fetchProductQualifications = (id) => {
  return dispatch => {
    dispatch(fetchProductQualificationsStart());
    axios.get(`gum/v1/product-rate/${id}`)
      .then(res => {
        dispatch(fetchProductQualificationsSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchProductQualificationsFail(err));
      });
  };
};
