import React from 'react';
import Swiper from 'react-id-swiper';

import Product from '../product/m-product';
import * as utils from '../../utils/utilities';
import './m-carrousel-products.scss';

const CarrouselProducts = (props) => {
  const displayNavigation = props.products.length > props.cols ? 'block' : 'none';
  const params = {
    slidesPerView: utils.breakpointsColumns.LG,
    spaceBetween: 24,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    breakpoints: {
      [utils.breakpoints.LG]: {
        slidesPerView: utils.breakpointsColumns.LG
      },
      [utils.breakpoints.MD]: {
        slidesPerView: utils.breakpointsColumns.MD
      },
      [utils.breakpoints.SM]: {
        slidesPerView: utils.breakpointsColumns.SM
      },
      [utils.breakpoints.XS]: {
        slidesPerView: utils.breakpointsColumns.XS
      }
    },
    navigation: {
      prevEl: '.swiper-button-prev.carrousel__prev',
      nextEl: '.swiper-button-next.carrousel__next'
    },
    renderPrevButton: () => (
      <button
        className="swiper-button-prev carrousel__prev carrousel__related-prev"
        style={{display: displayNavigation}} ></button>),
    renderNextButton: () => (
      <button
        className="swiper-button-next carrousel__next carrousel__related-next"
        style={{display: displayNavigation}} ></button>)
  };

  const products = props.products.map(
    product => (
      <div key={product.id}>
        <Product product={product} />
      </div>
      ));
  return <section className="article__related-articles mw">
    <h2>{props.title}</h2>
    <Swiper {...params}>
      {products}
    </Swiper>
  </section>;
}

export default CarrouselProducts;
