import React, { Component } from 'react';
import './m-modal-success.scss';
import { connect } from 'react-redux';
import successImg from './success-producto2.png';

class ModalSuccess extends Component {
  render() {
    return (
      <div className="success">

        <div className="success__img">
          <img src={successImg} alt="GUM Activital"/>
        </div>

        <div className="success__info">
          <div className="success__info-wrapper">
            <h2>¡Muchas Gracias!</h2>
            <p>{this.props.message}</p>
            <p>Atención al cliente <br/> <span>GUM ARGENTINA</span></p>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    message: state.modal.successMessage
  };
};

export default connect(mapStateToProps)(ModalSuccess);