import * as actionTypes from './actionTypes';
import axios from '../../utils/axios';

export const fetchBannersSuccess = (banners) => {
  return {
    type: actionTypes.FETCH_BANNERS_SUCCESS,
    banners: banners
  };
};

export const fetchBannersFail = (error) => {
  return {
    type: actionTypes.FETCH_BANNERS_FAIL,
    error: error
  };
};

export const fetchBanners = () => {
  return dispatch => {
    axios.get('/gum/v1/banners')
      .then(res => {
        dispatch(fetchBannersSuccess(res.data));
      })
      .catch(err => {
        dispatch(fetchBannersFail(err));
      });
  };
};

export const connect = () => {
  return dispatch => {
    axios.get('/gum/v1/connect').then().catch();
  }
};
