import * as actionTypes from '../actions/actionTypes';

const initialState = {
  banners: [],
  error: null,
  isLoaded: false
};

const fetchBannersSuccess = (state, action) => {
  return {
    ...state,
    banners: action.banners,
    isLoaded: true
  };
};

const fetchBannersFail = (state, action) => {
  return {
    ...state,
    error: action.error
  };
};

const reducer = (state=initialState, action) => {
  switch(action.type) {
    case actionTypes.FETCH_BANNERS_SUCCESS: return fetchBannersSuccess(state, action);
    case actionTypes.FETCH_BANNERS_FAIL: return fetchBannersFail(state, action);
    default: return state;
  }
};

export default reducer;