import React, { Component } from 'react';
import { connect } from 'react-redux';

import './m-modal-login.scss';
import handImg from './hand-gum.png';
import * as actions from '../../store/actions/index';
import * as formUtils from '../../utils/formUtilities';
import { checkValidity } from '../../utils/formUtilities';
import Button from '../ui/button/button';

class ModalLogin extends Component {
  state = {
    inputs: {
      email: {
        elementConfig: {
          type: 'email',
          placeholder: 'E-mail'
        },
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      },
      password: {
        elementConfig: {
          type: 'password',
          placeholder: 'Contraseña',
          autoComplete: 'password'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      }
    },
    formIsValid: true,
    formTouched: false
  }

  inputChangeHandler = (event, inputIdentifier) => {
    const updatedInputs = {
      ...this.state.inputs,
      [inputIdentifier]: {
        ...this.state.inputs[inputIdentifier],
        value: event.target.value,
        valid: checkValidity(event.target.value, this.state.inputs[inputIdentifier].validation),
        touched: true
      }
    };

    let formIsValid = this.state.formIsValid;
    if(this.state.formTouched) {
      formIsValid = Object.keys(updatedInputs)
        .reduce((valid, input) => updatedInputs[input].valid && valid, true);
    }

    this.setState({inputs: updatedInputs, formIsValid:formIsValid});
  }

  loginSubmitHandler = (event) => {
    event.preventDefault();

    const formIsValid = Object.keys(this.state.inputs)
      .reduce((valid, input) => this.state.inputs[input].valid && valid, true);

    if(formIsValid) {
      this.props.onLogin(
        this.state.inputs.email.value,
        this.state.inputs.password.value);
    }
    this.setState({formIsValid: formIsValid, formTouched: true});
  }

  render() {
    const customStyle = {
      bottom: '-2.2rem'
    };

    const inputObjects = this.state.inputs;
    const inputs = Object.keys(inputObjects)
      .map(input => (
        <input
          key={input}
          {...inputObjects[input].elementConfig}
          className={ !inputObjects[input].valid && this.state.formTouched ? 'invalid' : null }
          value={inputObjects[input].value}
          onChange={e => this.inputChangeHandler(e, input)} />
      ));

    let errorMessage = formUtils.FILL_ALL_FIELDS;
    if(this.props.errorCode) {
      errorMessage = this.props.errorCode === 403
        ? formUtils.INVALID_EMAIL_PASSWORD
        : formUtils.SOMETHING_WAS_WRONG
    }
    const errorBlock = !this.state.formIsValid || this.props.errorCode ? <div className="login__error">{errorMessage}</div> : null;

    return (
      <div>
        <div className="login cf">
          <div className="login__img">
            <img src={handImg} alt="" style={customStyle} />
          </div>

          <div className="login__form cf">
            <p className="login__title">Ingresá con tu cuenta de Gum Argentina</p>
            <form onSubmit={this.loginSubmitHandler} noValidate>
              {inputs}
              <Button config={{className:"submit"}} loading={this.props.loading}>Iniciar sesión</Button>
            </form>
            <a href="/" className="login__forget" onClick={this.props.onForgetPassword}>¿Olvidaste tu contraseña?</a>
            <p className="login__create-account">o creá tu cuenta <a href="/" onClick={this.props.onRegister}>aquí</a></p>
            {errorBlock}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.login.loading,
    errorCode: state.login.errorCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegister: e => dispatch(actions.toggleRegistry(e)),
    onLogin: (email, password) => dispatch(actions.login(email, password)),
    onForgetPassword: e => dispatch(actions.toggleForgetPassword(e))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalLogin);