import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loadMap: false,
  showMap: false,
  showRetails: false,
  showInitialLogin: false,
  showLogin: false,
  showRegistry: false,
  showContact: false,
  showContactSuccess: false,
  successMessage: '',
  suscriptionShowed: false,
  showSubscription: false,
  showForgetPassword: false
};

const showMap = (state) => {
  return {
    ...state,
    loadMap: true,
    showMap: true
  };
};

const hideMap = (state) => {
  return {
    ...state,
    showMap: false
  };
};

const toggleRetails = (state) => {
  return {
    ...state,
    showRetails: !state.showRetails
  };
};

const toggleInitialLogin = (state) => {
  return {
    ...state,
    showInitialLogin: !state.showInitialLogin
  };
};

const toggleLogin = (state) => {
  return {
    ...state,
    showInitialLogin: false,
    showLogin: !state.showLogin
  };
};

const toggleRegistry = (state) => {
  return {
    ...state,
    showInitialLogin: false,
    showLogin: false,
    showRegistry: !state.showRegistry
  };
};

const toggleContact = (state) => {
  return {
    ...state,
    showContact: !state.showContact
  };
};

const toggleContactSuccess = (state, action) => {
  return {
    ...state,
    showContact: false,
    showContactSuccess: !state.showContactSuccess,
    successMessage: action.message
  };
};

const toggleSubscription = (state) => {
  return {
    ...state,
    showSubscription: !state.showSubscription,
    suscriptionShowed: true
  };
};

const toggleForgetPassword = (state, action) => {
  return {
    ...state,
    showLogin: false,
    showForgetPassword: !state.showForgetPassword
  };
};

const reducer = (state=initialState, action) => {
  switch(action.type) {
    case actionTypes.SHOW_MAP: return showMap(state);
    case actionTypes.HIDE_MAP: return hideMap(state);
    case actionTypes.TOGGLE_RETAIL: return toggleRetails(state);
    case actionTypes.TOGGLE_INITIAL_LOGIN: return toggleInitialLogin(state);
    case actionTypes.TOGGLE_LOGIN: return toggleLogin(state);
    case actionTypes.TOGGLE_REGISTRY: return toggleRegistry(state);
    case actionTypes.TOGGLE_CONTACT: return toggleContact(state);
    case actionTypes.TOGGLE_CONTACT_SUCCESS: return toggleContactSuccess(state, action);
    case actionTypes.TOGGLE_SUBSCRIPTION: return toggleSubscription(state);
    case actionTypes.TOGGLE_FORGET_PASSWORD: return toggleForgetPassword(state, action)
    default: return state;
  }
};

export default reducer;