import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../ui/icons/m-icon';
import * as utils from '../../utils/utilities';

const Slide = (props) => {
  const { id, slug, title, excerpt, category, acf, post_type } = props.banner;
  const bannerImage = acf.banner_image ? acf.banner_image.url : '';
  const postUrl = `/${utils.postType[post_type]}/${id}/${slug}`;

  return (
    <div className="swiper-slide carrousel__slide cf">
      <span className="carrousel__category-label">
        <span>{category}</span> <Icon name={category} />
      </span>
      <Link to={postUrl}>
        <div className="carrousel__img" style={{backgroundImage: `url(${bannerImage})`}}></div>
      </Link>
      <div className="carrousel__info">
        <div className="carrousel__info-container">
          <Link to={postUrl}>
            <h1 className="carrousel__title">{title.rendered}</h1>
            <div className="carrousel__copy" ><p dangerouslySetInnerHTML={{__html: excerpt.rendered}}></p></div>
          </Link>
          {post_type==='products'
            ? <a className="carrousel__btn" href="/" onClick={props.shopClicked}>Quiero Comprar</a>
            : null }
        </div>
      </div>
    </div>
  );
}

export default Slide;
