import React from 'react';
import moment from 'moment';

const CommentsList = (props) => {

  const list = props.comments.map(comment => {
    const postDate = moment(comment.date);
    const now = moment();
    let date = 'Hoy';

    const yearsDiff = now.diff(postDate, 'years');
    const monthsDiff = now.diff(postDate, 'months');
    const weeksDiff = now.diff(postDate, 'weeks');
    const daysDiff = now.diff(postDate, 'days');

    if( yearsDiff > 0) {
      date = `Hace ${yearsDiff} año`+(yearsDiff > 1 ? 's' : '');
    } else if(monthsDiff > 0) {
      date = `Hace ${monthsDiff} mes`+(monthsDiff > 1 ? 'es' : '');
    } else if(weeksDiff > 0) {
      date = `Hace ${weeksDiff} semana`+(weeksDiff > 1 ? 's' : '');
    } else if(daysDiff === 1) {
      date = 'Ayer';
    } else if(daysDiff > 0) {
      date = `Hace ${daysDiff} días`;
    }

    return (
      <div key={comment.id} className="comments__comment">
        <h3>{comment.author}</h3>
        <span>{date}</span>
        <p>{comment.content}</p>
      </div>
    );
  });

  return (
    <>{list}</>
  );
};

export default CommentsList;