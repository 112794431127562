import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import Button from '../../ui/button/button';
import Input from '../../ui/input/input';
import * as formUtils from '../../../utils/formUtilities';
import axios from '../../../utils/axios';

const initialState = {
  inputs: {
    message: {
      elementType: 'textarea',
      elementConfig: {
        placeholder: 'Comentario'
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false
    },
    name: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Nombre'
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    },
    last_name: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        placeholder: 'Apellido'
      },
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: false
    }
  },
  formIsValid: true,
  formTouched: false,
  loading: false
};

class CommentsForm extends Component {
  recaptchaRef = React.createRef();

  state = initialState

  inputChangeHandler = (event, inputIdentifier) => {
    const updatedInputs = {
      ...this.state.inputs,
      [inputIdentifier]: {
        ...this.state.inputs[inputIdentifier],
        value: event.target.value,
        valid: formUtils.checkValidity(
          event.target.value, this.state.inputs[inputIdentifier].validation),
        touched: true
      }
    };

    this.setState({ inputs: updatedInputs});
  }

  onSubmitHandler = e => {
    e.preventDefault();
    const recaptchaValue = this.recaptchaRef.current.getValue();
    const formIsValid = formUtils.checkFormValidity(this.state.inputs);

    if(formIsValid && recaptchaValue) {
      axios.post(`gum/v1/comment/${this.props.id}`,
        formUtils.serializeInputValues(this.state.inputs))
      .then(res => {
        this.setState(initialState);
      })
      .catch(err => {
        this.setState({loading: false});
      });
    }

    this.setState({
      formIsValid: formIsValid,
      formTouched: true,
      loading: formIsValid && recaptchaValue
    });
  }

  render() {
    const inputObjects = this.state.inputs;
    const inputs = Object.keys(inputObjects)
      .map(input => {
        const inputElement = inputObjects[input];
        const classes = inputElement.elementConfig.className ? [inputElement.elementConfig.className] : [];
        if(!inputElement.valid && this.state.formTouched) {
          classes.push('invalid');
        }
        const config = {...inputElement.elementConfig, className: classes.join(' ')};
        return(
        <Input
          key={input}
          type={inputObjects[input].elementType}
          config={config}
          value={inputObjects[input].value}
          changed={e => this.inputChangeHandler(e, input)} />
      )});

    let erroMessage = null;
    if(!formUtils.checkFormValidity(this.state.inputs) && this.state.formTouched) {
      erroMessage = <div className="comments__error">{formUtils.FILL_ALL_FIELDS }</div>;
    }

    return (
      <div className="comments__create-comment cf">
        <h2>Dejar un comentario</h2>
        <form onSubmit={this.onSubmitHandler}>
          {inputs}
          <ReCAPTCHA
            ref={this.recaptchaRef}
            sitekey="6Ld8j78UAAAAAEZecIN8nr2q_7H_EuJcdtGO_-L1"
          />
          {erroMessage}
          <Button config={{className:"submit"}} loading={this.state.loading}>Enviar</Button>
        </form>
      </div>
    );
  }
}

export default CommentsForm;
