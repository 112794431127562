import React, { Component } from 'react';

import './c-social-footer.scss';


class NavSocialFooter extends Component {
  render() {
    return (
      <nav className={`footer__social-menu ${ this.props.view }`}>
        <ul className="footer__menu-list">
          <li className="footer__menu-item">
            <a href="https://www.facebook.com/GUMArgentina/?brand_redir=822027221259074" className="footer__link icon-social" title="Facebook" rel="noopener noreferrer" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className="footer__icon fb">
                <path fill="#fff" d="M19.8 0C8.7.1-.1 9.2 0 20.2S9.2 40.1 20.2 40c11-.1 19.8-9 19.8-20C39.9 8.9 30.9-.1 19.8 0zm5.5 12.2h-2.9c-.6 0-1.1.4-1.2 1V16.1h4.1l-.5 4.6h-3.6v13.1h-5.5v-13h-2.6v-4.6h2.6v-3c0-2.1 1-5.5 5.5-5.5h4.1v4.5z"/>
              </svg>
            </a>
          </li>

          <li className="footer__menu-item">
            <a href="https://www.instagram.com/gumargentina/?hl=es-la" className="footer__link icon-social" title="Instagram" rel="noopener noreferrer" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className="footer__icon in">
                <path fill="#fff" d="M25.8 10.6H13.7c-1.9 0-3.5 1.5-3.5 3.5V26c0 1.9 1.6 3.5 3.5 3.5h12.1c1.9 0 3.5-1.5 3.5-3.5V14.1c0-1.9-1.6-3.5-3.5-3.5zm-6 15.6c-3.4 0-6.2-2.9-6.1-6.3s2.9-6.2 6.3-6.1c3.4 0 6.1 2.8 6.1 6.2-.1 3.5-2.9 6.3-6.3 6.2zm6.4-11.1c-.8 0-1.5-.7-1.4-1.5 0-.8.7-1.5 1.5-1.4.8 0 1.4.7 1.4 1.5s-.7 1.4-1.5 1.4zm-6.4 1.4c-2 0-3.6 1.6-3.5 3.6s1.6 3.6 3.6 3.5c2 0 3.5-1.6 3.5-3.6 0-1.9-1.7-3.5-3.6-3.5zm0-16.5C8.7.1-.1 9.2 0 20.2S9.2 40.1 20.2 40c11-.1 19.8-9.1 19.8-20C39.9 8.9 30.9-.1 19.8 0zm12.1 26c0 3.4-2.8 6.1-6.2 6.1h-12c-3.4 0-6.1-2.7-6.1-6.1V14.1c0-3.4 2.8-6.1 6.1-6.1h12.1c3.4 0 6.1 2.7 6.2 6.1-.1 0-.1 11.9-.1 11.9z"/>
              </svg>
            </a>
          </li>

          <li className="footer__menu-item">
            <a href="https://www.youtube.com/channel/UCbd8deqe_432MndWroUTYbA" className="footer__link icon-social" title="YouTube" rel="noopener noreferrer" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className="footer__icon yt">
                <path fill="#fff" d="M19.8 0C8.7.1-.1 9.2 0 20.2S9.2 40.1 20.2 40C31.1 39.9 40 31 40 20 39.9 8.9 30.9-.1 19.8 0zm9.5 20.8l-15.9 7.5c-.3.2-.7 0-.8-.3 0-.1-.1-.2-.1-.3V12.3c0-.4.3-.6.7-.6.1 0 .2 0 .3.1l15.9 8c.3.2.4.5.3.8-.1 0-.2.1-.4.2z"/>
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
};

export default NavSocialFooter;