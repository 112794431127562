import React from 'react';
import iconUser from '../icon_user.svg';

const account = (props) => {
  let content = (
    <>
      <li className="preheader__menu-item">
        <a href="/" className="preheader__link cf mobile-view" title="Registrarse" rel="noopener noreferrer" onClick={props.registered}><span>Registrarse</span></a>
      </li>
      <li className="preheader__menu-item">
        <a href="/" className="preheader__link cf mobile-view" title="Ingresar" rel="noopener noreferrer" onClick={props.loggedIn}><span>Ingresar</span> <img src={ iconUser } className="preheader__icon mobile-icon" alt="Ingresar" width="40" height="40" /></a>
      </li>
    </>
  );

  if(props.isAuthenticated) {
    content = (
      <>
        <li className="preheader__menu-item">
          <a href="/" className="preheader__link cf" title="Salir" rel="noopener noreferrer" onClick={props.loggedOut}><span>Salir</span></a>
        </li>
        <li className="preheader__menu-item">
          <span className="preheader__link cf"><span className="name">{props.userName}</span> <img src={ iconUser } className="preheader__icon" alt="Usuario" width="40" height="40" /></span>
        </li>
      </>
    );
  }

  return content;
};

export default account;