import React, { Component } from 'react';
import { connect } from 'react-redux';

import TooltipModal from './tooltipModal/c-tooltip-modal';
import Backdrop from '../ui/backdrop/m-backdrop';
import * as actions from '../../store/actions/index';
import './m-share.scss';

class Share extends Component {
  isMobile = () => {
    return (/(android|iphone|ipad|mobile)/i.test(navigator.userAgent));
  }

  onCopyToClipboard = (evt, text) => {
    evt.preventDefault();
    const url = decodeURIComponent(text);

    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.setAttribute("id", "clipboard_input_id");
    document.getElementById("clipboard_input_id").value=url;
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    this.props.onCloseShareModal();
  }

  render() {
    return (
      <>
        <div className="tooltip__container" style={
          {
            display: this.props.showModal ? 'block' : 'none',
            left: this.props.leftPosition,
            top:this.props.topPosition
          }
        }>
          <TooltipModal
            url={this.props.url}
            title={this.props.title}
            isMobile={this.isMobile()}
            copied={this.onCopyToClipboard} />
        </div>
        <Backdrop
          show={this.props.showModal}
          clicked={this.props.onCloseShareModal}
          style={{opacity: 0}} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    showModal: state.share.showShareModal,
    leftPosition: state.share.leftPosition,
    topPosition: state.share.topPosition,
    url: state.share.url,
    title: state.share.title
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseShareModal: () => dispatch(actions.hideShareTooltip())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Share);
