import React from 'react';
import { Link } from 'react-router-dom';
import './c-img.scss';

const Img = (props) => {
  let imgUrl = props.imgUrl;
  let imgContent = <img className="product__img" src={ imgUrl } alt={ props.title }/>;

  if(props.isOutstanding) {
    const {img, imgWide} = props.imgUrl;
    imgContent = (
      <picture>
        <source media="(max-width: 519px)" srcSet={ img } />
        <source media="(max-width: 679px)" srcSet={ imgWide } />
        <source media="(min-width: 768px)" srcSet={ img } />
        <img src={ img } className="product__img" alt={ props.title } />
      </picture>
    );
  }

  return (
    <Link to={props.url}>
      <div>
        {imgContent}
      </div>
    </Link>
  );
};

export default Img;