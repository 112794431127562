import React, { PureComponent } from 'react';
import './m-instagram-feed.scss';
import InstagramGallery from "./m-instagram-gallery.js";

const INSTAGRAM_ID = "6780328085";
const THUMBNAIL_WIDTH = 640;
const PHOTO_COUNT = 5;

class InstagramFeed extends PureComponent {

  render() {
    return (
      <section className="mw mt5">
        <div className="feed cf">
          <h2 className="feed__title">Seguinos en Instagram</h2>

          <InstagramGallery
            userId={INSTAGRAM_ID}
            thumbnailWidth={THUMBNAIL_WIDTH}
            photoCount={PHOTO_COUNT}
          />
        </div>
      </section>
    );
  }
}

export default InstagramFeed;