import React from 'react';

import './m-carrousel.scss';
import Slide from './m-slide';
import Swiper from 'react-id-swiper';
import { Pagination, Navigation } from 'swiper/dist/js/swiper.esm';

const Carrousel = (props) => {
  const navigationParams = {
    modules: [Pagination, Navigation],
    pagination: {
      el: '.swiper-pagination.carrousel__pagination',
      type: 'bullets',
      clickable: true
    },
    navigation: {
      prevEl: '.swiper-button-prev.carrousel__prev',
      nextEl: '.swiper-button-next.carrousel__next'
    },
    renderPrevButton: () => <button className="swiper-button-prev carrousel__prev"></button>,
    renderNextButton: () => <button className="swiper-button-next carrousel__next"></button>
  };

  let params = {
    ContainerEl: 'div',
    containerClass: 'carrousel',
    themeColor: '#6bb895'
  }

  if(props.banners.length > 1) {
    params = {...navigationParams, ...params};
  }

  const {banners, isLoaded} = props;

  if(isLoaded) {
    const sliders = banners.map(banner => <div key={banner.id}>
          <Slide banner={banner} shopClicked={props.shopClicked}/>
        </div>);
    return (
      <section className="mw mt5">
        <Swiper {...params}>
          {sliders}
        </Swiper>
    </section>
    );
  }
  return null;
}

export default Carrousel;