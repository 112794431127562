import React from 'react';
import { Link } from 'react-router-dom';

import './c-tags.scss';

const Tags = (props) => {
  let tags = null;

  if(props.tags) {
    tags = props.tags.map(tag => (
      <Link key={tag.id} to={`/tag/${tag.slug}`}>
        <span> {tag.name} </span>
      </Link>));
  }

  return (
    <div className="product__tags">
      {tags}
    </div>
  );
};

export default Tags;