import React, {Component} from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { connect } from 'react-redux';

import Button from '../../ui/button/button';
import Input from '../../ui/input/input';
import * as formUtils from '../../../utils/formUtilities';
import axios from '../../../utils/axios';
import * as actions from '../../../store/actions';

class CommentsQualificationsForm extends Component {
  state = {
    inputs: {
      title: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Título'
        },
        value: '',
        validation: {
          required: true,
        },
        valid: false,
        touched: false
      },
      message: {
        elementType: 'textarea',
        elementConfig: {
          placeholder: 'Comentario'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
    },
    rating: 0,
    formIsValid: true,
    formTouched: false,
    loading: false
  };

  inputChangeHandler = (event, inputIdentifier) => {
    const updatedInputs = {
      ...this.state.inputs,
      [inputIdentifier]: {
        ...this.state.inputs[inputIdentifier],
        value: event.target.value,
        valid: formUtils.checkValidity(
          event.target.value, this.state.inputs[inputIdentifier].validation),
        touched: true
      }
    };

    this.setState({ inputs: updatedInputs});
  }

  onStarClick(nextValue, prevValue, name) {
    this.setState({rating: nextValue});
  }

  onSubmitHandler = e => {
    e.preventDefault();
    let formIsValid = formUtils.checkFormValidity(this.state.inputs);
    formIsValid = this.state.rating > 0 && formIsValid;
    if(formIsValid) {
      const data = {
        ...formUtils.serializeInputValues(this.state.inputs),
        rating: this.state.rating
      }
      axios.post(`gum/v1/product-rate/${this.props.id}`, data)
        .then(res => {
          let updatedInputs = {...this.state.inputs}
          // eslint-disable-next-line
          for(let input in updatedInputs) {
            updatedInputs = {
              ...updatedInputs,
              [input]: {
                ...updatedInputs[input],
                value: ''
              }
            };
          }
          this.setState({loading: false, inputs: updatedInputs, rating: 0});
          this.props.onFetchComments(this.props.id);
        })
        .catch(err => {
          this.setState({loading: false});
        });
    }
    this.setState({
      formIsValid: formIsValid,
      formTouched: true,
      loading: formIsValid
    });
  }

  render() {
    const { rating } = this.state;
    const inputObjects = this.state.inputs;
    const inputs = Object.keys(inputObjects)
      .map(input => {
        const inputElement = inputObjects[input];
        const classes = inputElement.elementConfig.className ? [inputElement.elementConfig.className] : [];
        if(!inputElement.valid && this.state.formTouched) {
          classes.push('invalid');
        }
        const config = {...inputElement.elementConfig, className: classes.join(' ')};
        return(
        <Input
          key={input}
          type={inputObjects[input].elementType}
          config={config}
          value={inputObjects[input].value}
          changed={e => this.inputChangeHandler(e, input)} />
      )});

    let erroMessage = null;
    if(!formUtils.checkFormValidity(this.state.inputs) && this.state.formTouched) {
      erroMessage = <div className="comments__error comments-have-qualifications__error">{formUtils.FILL_ALL_FIELDS }</div>;
    }

    return (
      <div className="comments__create-comment cf">
        <h2>Calificar producto</h2>
        <form onSubmit={this.onSubmitHandler}>
          {inputs}
          <div>
            <span>Calificación:</span>
            <StarRatingComponent
                name="product__rating-form"
                starCount={5}
                value={rating}
                starColor="#79d7bd"
                emptyStarColor="#999999"
                onStarClick={this.onStarClick.bind(this)}
              />
          </div>
          {erroMessage}
          <Button config={{className:"submit"}} loading={this.state.loading}>Calificar</Button>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchComments: (id) => dispatch(actions.fetchProductQualifications(id))
  };
};

export default connect(null, mapDispatchToProps)(CommentsQualificationsForm);