import React from 'react';
import './m-related-articles.scss';

import Spinner from '../ui/spinner/spinner';
import CarrouselProducts from '../moduleCarrouselProducts/m-carrousel-products';

const RelatedArticles = (props) => {

  if(props.products && props.products.length > 0 ) {
    return <CarrouselProducts border={props.border} title={props.title} products={props.products} cols={props.cols} type="articles" />;
  } else if(props.loading) {
    return <Spinner />;
  }
  return null;
}

export default RelatedArticles;
