import React from 'react';

import Interdental from './interdental.svg';
import Souluciones from './soluciones.svg';
import ToothPaste from './toothpaste.svg';

const icon = (props) => {
    const icons = {
       'Productos': ToothPaste,
       'Interdental': Interdental,
       'Ortodoncia': ToothPaste,
       'Soluciones': Souluciones
    }

    if(icons[props.name]) {
        return <img src={ icons[props.name] } alt=""/>
    }

    return null;
};

export default icon;