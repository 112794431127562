import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './m-modal-registry.scss';
import Input from '../ui/input/input';
import Button from '../ui/button/button';
import * as formUtils from '../../utils/formUtilities';
import axios from '../../utils/axios';
import * as actions from '../../store/actions/index';

class ModalRegistry extends Component {
  state = {
    inputs: {
      name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Nombre',
          className: 'registry__input'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      last_name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Apellido',
          className: 'registry__input'
        },
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'Mail',
          className: 'registry__input'
        },
        relatedField: 'repeat_email',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      },
      repeat_email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'Repetir mail*',
          className: 'registry__input'
        },
        relatedField: 'email',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      },
      password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Contraseña',
          className: 'registry__input',
          autoComplete: 'off'
        },
        relatedField: 'repeat_password',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      repeat_password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Repetir Contraseña',
          className: 'registry__input',
          autoComplete: 'off'
        },
        relatedField: 'password',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      birthdate: {
        elementType: 'input',
        elementConfig: {
          type: 'date',
          placeholder: 'Mail',
          className: 'registry__input'
        },
        value: '',
        validation: {},
        valid: true,
        touched: false
      },
      gender: {
        elementType: 'select',
        elementConfig: {
          options: [
            {value: '', displayValue:'Género'},
            {value: 'femenino', displayValue:'Femenino'},
            {value: 'masculino', displayValue:'Masculino'}
          ],
          className: 'registry__input'
        },
        value: '',
        validation: {},
        valid: true
      }
    },
    newsletterRadios: {
      elementType: 'checkboxgroup',
      elementConfig: {
        className: 'registry__input radio'
      },
      options: [
        {value:'cepillos_adultos', displayValue:'Cepillos Adultos', isChecked: false},
        {value:'cepillos_para_niños', displayValue:'Cepillos para niños', isChecked: false},
        {value:'limpieza_entre_los_dientes', displayValue:'Limpieza entre los dientes', isChecked: false},
        {value:'cuidado_ortodontico', displayValue:'Cuidado Ortodóntico', isChecked: false},
        {value:'no_uso_productos_gum', displayValue:'No uso productos GUM', isChecked: false}
      ],
      value: '',
      valid: true,
      validation: {}
    },
    acceptTerms: false,
    receiveNewletter: false,
    step2: false,
    errorMessage: '',
    formIsValid: true,
    formTouched: false,
    loading: false
  }

  toogleSteps = () => {
    if(!this.state.step2 && (
        !formUtils.checkFormValidity(this.state.inputs) || !this.state.acceptTerms
      )) {
      const errorMessage = !this.state.acceptTerms ? formUtils.ACCEPT_TERMS : '';
      return this.setState({ formIsValid: false, formTouched: true, errorMessage:errorMessage });
    }

    this.setState(prevState => {
      return {
        step2: !prevState.step2
      };
    });
  }

  checkboxChangeHandler = (e) => {
    const {name} = e.target;
    this.setState(prevState => {
      return {
        [name]: !prevState[name]
      };
    });
  }

  checkboxGroupChangeHandler = (e) => {
    const options = this.state.newsletterRadios.options.map(option => {
      const modifiedOption = {...option};
      if(option.value === e.target.value) {
        modifiedOption.isChecked = e.target.checked;
      }
      return modifiedOption;
    });

    this.setState({
      newsletterRadios : {
        ...this.state.newsletterRadios,
        options
      }
    });
  }

  inputChangeHandler = (event, inputIdentifier) => {
    let isValid = formUtils.checkValidity(event.target.value, this.state.inputs[inputIdentifier].validation);
    let errorMessage = '';

    const relatedField = this.state.inputs[inputIdentifier].relatedField;
    if(relatedField) {
      isValid = formUtils.checkEqualValues(event.target.value, this.state.inputs[relatedField].value) && isValid;
    }

    let updatedInputs = {
      ...this.state.inputs,
      [inputIdentifier]: {
        ...this.state.inputs[inputIdentifier],
        value: event.target.value,
        valid: isValid,
        touched: true
      }
    };

    if(relatedField) {
      updatedInputs = {
        ...updatedInputs,
        [relatedField]: {
          ...this.state.inputs[relatedField],
          valid: isValid
        }
      }
    }

    if(!isValid && this.state.inputs[inputIdentifier].validation.isEmail) {
      errorMessage = formUtils.INVALID_EMAIL;
    }
    if(relatedField && !formUtils.checkEqualValues(event.target.value, this.state.inputs[relatedField].value)) {
      errorMessage = formUtils.FIELDS_NOT_MATCH;
    }

    let formIsValid = this.state.formIsValid;
    if(this.state.formTouched) {
      formIsValid = formUtils.checkFormValidity(updatedInputs);
    }

    this.setState({
      inputs: updatedInputs,
      formIsValid: formIsValid,
      errorMessage: errorMessage
    });
  }

  onSubmitHandler = e => {
    e.preventDefault();
    let formIsValid = formUtils.checkFormValidity(this.state.inputs);

    let errorMessage = this.state.errorMessage;

    if(formIsValid && !this.state.acceptTerms) {
      errorMessage = formUtils.ACCEPT_TERMS;
      formIsValid = false;
    } else if(formIsValid) {
      const preferences = this.state.newsletterRadios.options
        .filter(option => option.isChecked)
        .map(option => option.displayValue);

      const data = {
        ...formUtils.serializeInputValues(this.state.inputs),
        newsletter_suscribed: this.state.receiveNewletter ? '1' : '0',
        newsletter_option: preferences.join(', ')
      };

      axios.post('/gum/v1/account/new', data)
      .then(res => {
        const {token, user_display_name} = res.data;

        this.setState({ loading:false },
          () => this.setSuccess(token, user_display_name));
      })
      .catch(err => {
        const errorMessage = err.response.data.code === 'email_exist'
          ? formUtils.EMAIL_EXISTS : formUtils.SOMETHING_WAS_WRONG;

        this.setState({loading:false, formIsValid:false, errorMessage:errorMessage});
      });
    }

    this.setState({
      formIsValid: formIsValid,
      formTouched: true,
      loading: formIsValid,
      errorMessage: errorMessage
    });
  }

  setSuccess = (token, name) => {
    this.props.onSuccess();
    this.props.showMessage('Se ha creado la cuenta exitosamente.');
    this.props.onLogin(token, name);
  }

  render() {
    const inputs = Object.keys(this.state.inputs).map(input => {
      const inputElement = this.state.inputs[input];
      const classes = inputElement.elementConfig.className ? [inputElement.elementConfig.className] : [];
      if(!inputElement.valid && this.state.formTouched) {
        classes.push('invalid');
      }
      const config = {...inputElement.elementConfig, className: classes.join(' ')};
      return <Input key={input}
        type={inputElement.elementType}
        config={config}
        value={inputElement.value}
        changed={(e) => this.inputChangeHandler(e, input)} />
    });

    let erroMessage = null;
    if(!this.state.formIsValid) {
      erroMessage = <div className="registry__error">{this.state.errorMessage || formUtils.FILL_ALL_FIELDS }</div>;
    }

    let content = (
      <>
        <h2 className="registry__title">Formulario de registro</h2>
        <p className="registry__copy">¡No te preocupes! Cuidaremos tus datos personales tan bien como vos cuidás tus dientes :D</p>
        <form onSubmit={this.onSubmitHandler} noValidate>
          {inputs}
          <div className="registry__input radio">
            <label>
              <input type="checkbox" name='acceptTerms' checked={this.state.acceptTerms} onChange={this.checkboxChangeHandler} /> Acepto los <Link to="/terms" target="_blank">términos y condiciones</Link>
            </label>
            <label>
              <input type="checkbox" name='receiveNewletter' checked={this.state.receiveNewletter} onChange={this.checkboxChangeHandler} /> Acepto recibir información de novedades y promociones en mi correo electrónico.
            </label>
          </div>
          { !this.state.receiveNewletter
            ? <Button config={{className:"registry__submit"}} loading={this.state.loading} >Finalizar</Button>
            : <button className="registry__submit" type="button" onClick={this.toogleSteps}>Siguiente</button> }
          { erroMessage }
        </form>
      </>
    );
    if(this.state.step2) {
      const {elementType, elementConfig, options, value} = this.state.newsletterRadios;
      content = (
        <>
          <button className="registry__back" type="button" onClick={this.toogleSteps}>Atras</button>
          <h2 className="registry__title">Queremos conocerte mejor ;)</h2>
          <p className="registry__copy">¿Qué productos son de tu interés?</p>
          <form onSubmit={this.onSubmitHandler} noValidate>
            <Input
              type={elementType}
              config={elementConfig}
              options={options}
              changed={this.checkboxGroupChangeHandler}
              value={value} />
            <Button config={{className:"registry__submit"}} loading={this.state.loading} >Finalizar</Button>
            { erroMessage }
          </form>
        </>
      );
    }
    return (
      <div className="registry cf">
        {content}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSuccess: () => dispatch(actions.toggleRegistry()),
    showMessage: (message) => dispatch(actions.toggleContactSuccess(null, message)),
    onLogin: (token, name) => dispatch(actions.loginSuccess(token, name))
  };
};

export default connect(null, mapDispatchToProps)(ModalRegistry);