import React from 'react';
import Swiper from 'react-id-swiper';
import { Navigation } from 'swiper/dist/js/swiper.esm';

import './m-product-header.scss';
import ProductTags from '../product/productTags/c-tags';
import ProductStars from '../moduleCommentsQualifications/productStars/m-product-stars';

const ProductHeader = (props) => {
  const { tags, title, description, sku, images } = props;

  const navigationParams = {
    modules: [Navigation],
    navigation: {
      prevEl: '.swiper-button-prev.carrousel__prev',
      nextEl: '.swiper-button-next.carrousel__next'
    },
    renderPrevButton: () => <button className="swiper-button-prev carrousel__prev"></button>,
    renderNextButton: () => <button className="swiper-button-next carrousel__next"></button>
  };

  let params = {
    ContainerEl: 'div'
  }

  if(images.length > 1) {
    params = {...navigationParams, ...params};
  }

  const imageSliders = images.map(image => (
    <div key={image.id}><img src={image.url} alt={title.rendered} /></div>
  ));
  return (
    <article className="product__content cf">
      <div className="product__content-info">
        <header>
          <div className="tags">
            <ProductTags tags={tags} />
          </div>

          <h1 className="product__content-header">{title.rendered}</h1>
          {props.count ? <ProductStars score={props.score} count={props.count} /> : null}
        </header>
        { description
        ? <div className="product__content-copy" dangerouslySetInnerHTML={{__html: description}}></div>
        : null }
      </div>

      <div className="product__content-img">
        <Swiper {...params}>
          {imageSliders}
        </Swiper>
      </div>

      <div className="product__content-code">
        {sku ? <span>SKU# {sku}</span> : null}
      </div>

      <button className="product__content-cta" onClick={props.shopClicked}>Comprar</button>
    </article>
  )
};

export default ProductHeader;
