import React from 'react';

import './m-backdrop.scss';

const Backdrop = (props) => (
    props.show
        ? <div className='Backdrop' style={props.style} onClick={props.clicked}></div>
        : null
);


export default Backdrop;