import React, { Component, Fragment } from 'react';

import Backdrop from '../backdrop/m-backdrop';
import './m-modal.scss';

class Modal extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
  }

  render() {
    let classes = ['Modal'];
    if(this.props.customClass) {
      classes.push(this.props.customClass);
    }
    return (
      <Fragment>
        <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
        <div className={classes.join(' ')}
        style={{
          transform: this.props.show ? 'translateY(0)' : 'translateY(-200vh)',
          opacity: this.props.show ? '1' : '0'
        }} >
          <div className="close" onClick={this.props.modalClosed}></div>
          { this.props.children }
        </div>
      </Fragment>
    );
  }
}

export default Modal;