import * as actionTypes from './actionTypes';

export const showShareTooltip = (data) => {
  return {
    type: actionTypes.SHOW_SHARE_TOOLTIP,
    leftPosition: data.leftPosition,
    topPosition: data.topPosition,
    url: data.url,
    title: data.title
  };
};

export const hideShareTooltip = () => {
  return {
    type: actionTypes.HIDE_SHARE_TOOLTIP
  };
};
