import React from 'react';

const input = (props) => {
  switch (props.type) {
    case 'input':
      return <input {...props.config} value={props.value} onChange={props.changed} />;
    case 'textarea':
      return <textarea {...props.config} onChange={props.changed} value={props.value}></textarea>;
    case 'select':
      return (
        <select
          className={props.config.className || ''}
          value={props.value}
          onChange={props.changed} >
          {props.config.options.map(option =>
            <option key={option.value} value={option.value}>{option.displayValue}</option>)}
        </select>);
    case 'radiogroup':
      return (
        <div {...props.config}>
          {props.options.map(option => (
            <label key={option.value}>
              <input
                type="radio"
                value={option.value}
                checked={option.value === props.value}
                onChange={props.changed} /> {option.displayValue}
            </label>
          ))}
        </div>
      );
    case 'checkboxgroup':
      return (
        <div {...props.config}>
          {props.options.map((option, i) => (
            <label key={`Checkbox-${i}`}>
              <input
                type="checkbox"
                value={option.value}
                checked={option.isChecked}
                onChange={props.changed} /> {option.displayValue}
            </label>
          ))}
        </div>
      )
    default:
      return <input {...props.config} value={props.value} onChange={props.changed} />;
  }
};

export default input;