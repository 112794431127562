import React from 'react';

import StarRatingComponent from 'react-star-rating-component';
import './m-product-stars.scss';
import * as utils from '../../../utils/utilities';

const  ProductStars = (props) => {
  const ratingDescription = props.count
    ? `${props.count} calificaciones`
    : utils.getScoreDescription(props.score);
  return (
    <div className="product__rating cf">
      <StarRatingComponent
        name="product__rating-stars"
        editing={false}
        starCount={5}
        value={props.score}
        starColor="#79d7bd"
        emptyStarColor="#999999"
      />

      <span className="product__rating-average">{ratingDescription}</span>
    </div>
  );
};

export default ProductStars;