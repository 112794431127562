import React from 'react';

import './m-comments-qualifications.scss';
import ProductStars from './productStars/m-product-stars';
import CommentsQualificationsForm from './commentsQualificationsForm/m-comments-qualifications-form';

const CommentsQualifications = (props) => {
  const comments = props.comments.map(comment =>
    <div className="comments__comment" key={comment.id}>
      <ProductStars score={parseInt(comment.rating, 10)} />
      <h3>{comment.title}</h3>
      <p>{comment.content}</p>
    </div>
  );
  return (
    <section className="comments comments-have-qualifications">
      {props.comments.length ? <h2>Comentarios y Calificaciones</h2> : null}
      {comments}
      <CommentsQualificationsForm id={props.id}/>
    </section>
  );
};

export default CommentsQualifications;