import React from 'react';

import './m-article-content.scss';
import Comments from '../moduleComments/m-comments';
import ProductTags    from '../product/productTags/c-tags';

const articleContent = (props) => (
  <section className="article__content">
    <div dangerouslySetInnerHTML={{__html: props.content.rendered}}></div>
    <ProductTags tags={props.tags} />
    <Comments
      comments={props.comments}
      error={props.errorComments}
      loading={props.loadingComments}
      id={props.id} />
  </section>
);

export default articleContent;
