import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Modal from '../../components/ui/modal/m-modal';
import MapModule from '../../components/moduleMap/m-map';
import ModalRetails from '../../components/moduleModalRetails/m-modal-retails';
import ModalLogin from '../../components/moduleModalLogin/m-modal-login';
import ModalInitialLogin from '../../components/moduleModalInitialLogin/m-modal-initial-login';
import ModalRegistry from '../../components/moduleModalRegistry/m-modal-registry';
import ModalContact from '../../components/moduleModalContactForm/m-modal-contact-form';
import ModalContactSuccess from '../../components/moduleModalSuccess/m-modal-success';
import ModalSubscription from '../../components/moduleModalSubscription/m-modal-subscription';
import ModalForgetPassword from '../../components/moduleModalForgetPassword/m-modal-forget-password';
import * as actions from '../../store/actions/index';

class Modals extends PureComponent {
  state = {
    modals: [
      {
        modal: 'retail',
        show: 'showRetails',
        customClass: 'ModalRetails',
        component: <ModalRetails />
      },
      {
        modal: 'login',
        show: 'showLogin',
        customClass: 'ModalLogin',
        component: <ModalLogin />
      },
      {
        modal: 'registry',
        show: 'showRegistry',
        customClass: 'ModalRegistry',
        component: <ModalRegistry />
      },
      {
        modal: 'contact',
        show: 'showContact',
        customClass: 'ModalContact',
        component: <ModalContact />
      },
      {
        modal: 'contactSuccess',
        show: 'showContactSuccess',
        customClass: 'ModalSuccess',
        component: <ModalContactSuccess />
      },
      {
        modal: 'subscription',
        show: 'showSubscription',
        customClass: 'ModalSubscription',
        component: <ModalSubscription />
      },
      {
        modal: 'initialLogin',
        show: 'showInitialLogin',
        customClass: '',
        component: <ModalInitialLogin />
      },
      {
        modal: 'forgetPassword',
        show: 'showForgetPassword',
        customClass: '',
        component: <ModalForgetPassword />
      }
    ]
  }

  render() {
    let mapModal = null;
    if(this.props.loadMap) {
      mapModal = (
        <Modal
          show={this.props.showMap}
          customClass="ModalMap"
          modalClosed={this.props.onModalClosed} >
          <MapModule />
        </Modal>
      );
    }

    const modals = this.state.modals.map(m => (
      <Modal
        key={m.modal}
        show={this.props[m.show]}
        customClass={m.customClass ? m.customClass : null}
        modalClosed={this.props.onToggle(m.modal)} >
          {m.component}
      </Modal>
    ));
    return (
      <section>
        {mapModal}
        {modals}
      </section>
    );
  }
}

const toggleModals = (dispatch, modal) => {
  switch (modal) {
    case 'retail': return () => dispatch(actions.toggleRetails());
    case 'login': return () => dispatch(actions.toggleLogin());
    case 'initialLogin': return () => dispatch(actions.toggleInitialLogin());
    case 'registry': return () => dispatch(actions.toggleRegistry());
    case 'contact': return () => dispatch(actions.toggleContact());
    case 'contactSuccess': return () => dispatch(actions.toggleContactSuccess());
    case 'subscription': return () => dispatch(actions.toggleSubscription())
    case 'forgetPassword': return () => dispatch(actions.toggleForgetPassword())
    default: return;
  }
}

const mapStateToProps = state => {
  return {
    showMap: state.modal.showMap,
    loadMap: state.modal.loadMap,
    showRetails: state.modal.showRetails,
    showLogin: state.modal.showLogin,
    showInitialLogin: state.modal.showInitialLogin,
    showRegistry: state.modal.showRegistry,
    showContact: state.modal.showContact,
    showContactSuccess: state.modal.showContactSuccess,
    showSubscription: state.modal.showSubscription,
    showForgetPassword: state.modal.showForgetPassword
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onModalClosed: () => dispatch(actions.hideMap()),
    onToggle: modal => toggleModals(dispatch, modal)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modals);