import React from 'react';

import Pharmacy from '../pharmacy/c-pharmacy';
import Spinner from '../../ui/spinner/spinner';
import './c-navMap.scss';

const  NavMap = (props) => {
  const {
    clicked,
    isSearching,
    locations,
    onCurrentPosition,
    submited,
    searchChanged,
    searchValue
  } = props;

  const pharmaciesList = locations.map(
    location => <li key={location.id}>
        <a href="/" onClick={(e) => clicked(e, location)}><Pharmacy {...location} /></a>
      </li>
  );

  const getPosition = (e) => {
    e.preventDefault();
    onCurrentPosition();
  };

  return (
    <nav className="map__content">
      <div className="map__bajada">
        <h1>Encontrá un punto de venta</h1>
        <p>Busca las tiendas más cercanas a tu domicilio</p>
      </div>
    <div className="map__search">
      <form onSubmit={submited}>
        <input
          className="map__input"
          type="text"
          placeholder="Escribí tu dirección"
          value={searchValue}
          onChange={searchChanged} />
        {!isSearching
          ? <button className="map__submit" type="submit">Buscar</button>
          : <Spinner style={{margin: 0, float: 'right'}} />}
        <a href="/" className="map__actualLocation" onClick={getPosition}>
          <div className="map__iconLocation">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
              <path fill="#2f6758" d="M24 11h-2.051c-.469-4.725-4.224-8.48-8.949-8.95v-2.05h-2v2.05c-4.725.47-8.48 4.225-8.949 8.95h-2.051v2h2.051c.469 4.725 4.224 8.48 8.949 8.95v2.05h2v-2.05c4.725-.469 8.48-4.225 8.949-8.95h2.051v-2zm-11 8.931v-3.931h-2v3.931c-3.611-.454-6.478-3.32-6.931-6.931h3.931v-2h-3.931c.453-3.611 3.32-6.477 6.931-6.931v3.931h2v-3.931c3.611.454 6.478 3.319 6.931 6.931h-3.931v2h3.931c-.453 3.611-3.32 6.477-6.931 6.931zm1-7.931c0 1.104-.896 2-2 2s-2-.896-2-2 .896-2 2-2 2 .896 2 2z"/>
            </svg>
          </div>
        <span>Utilizar mi ubicación actual</span></a>
      </form>
    </div>
    <div className="map__contentStores">
      <ul className="map__listStores">
        {pharmaciesList}
      </ul>
    </div>
    </nav>
 );
}
export default NavMap;