import * as actionTypes from '../actions/actionTypes';

const initialState = {
  post: null,
  relatedProducts: [],
  relatedArticles: [],
  error: null,
  loading: false,
  loadingRelatedProducts: false,
  loadingRelatedArticles: false,
  totalRelatedProducts: 0,
  loadingComments: false,
  errorComments: null,
  comments: []
}

const fetchPostStart = (state, action) => {
  return {
    ...state,
    loading: true,
    post: null,
    error: null,
    relatedProducts: [],
    relatedArticles: []
  };
};

const fetchPostSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    post: action.post
  };
};

const fetchPostFail = (state, action) => {
  return {
    ...state,
    loading:false,
    error: action.error
  };
};

const fetchRelatedProductsStart = (state, action) => {
  return {
    ...state,
    loadingRelatedProducts: true
  }
};

const fetchRelatedProductsSuccess = (state, action) => {
  return {
    ...state,
    relatedProducts: action.products,
    loadingRelatedProducts: false,
    totalRelatedProducts: action.totalProducts
  }
};

const fetchRelatedProductsFail = (state, action) => {
  return {
    ...state,
    loadingRelatedProducts: false,
    error: action.error
  };
};

const fetchRelatedArticlesStart = (state, action) => {
  return {
    ...state,
    loadingRelatedArticles: true
  }
};

const fetchRelatedArticlesSuccess = (state, action) => {
  return {
    ...state,
    relatedArticles: action.articles,
    loadingRelatedArticles: false
  }
};

const fetchRelatedArticlesFail = (state, action) => {
  return {
    ...state,
    loadingRelatedArticles: false,
    error: action.error
  };
};

const fetchArticleCommenstStart = (state, action) => {
  return {
    ...state,
    loadingComments: true
  };
};

const fetchArticleCommentsSuccess = (state, action) => {
  return {
    ...state,
    comments: action.comments,
    loadingComments: false
  };
};

const fetchArticleCommentsFail = (state, action) => {
  return {
    ...state,
    errorComments: action.error,
    loadingComments: false
  };
};

const reducer = (state=initialState, action) => {
  switch(action.type){
    case actionTypes.FETCH_POST_START: return fetchPostStart(state, action);
    case actionTypes.FETCH_POST_SUCCESS: return fetchPostSuccess(state, action);
    case actionTypes.FETCH_POST_FAIL: return fetchPostFail(state, action);
    case actionTypes.FETCH_RELATED_PRODUCTS_START: return fetchRelatedProductsStart(state, action);
    case actionTypes.FETCH_RELATED_PRODUCTS_SUCCESS: return fetchRelatedProductsSuccess(state, action);
    case actionTypes.FETCH_RELATED_PRODUCTS_FAIL: return fetchRelatedProductsFail(state, action);
    case actionTypes.FETCH_RELATED_ARTICLES_START: return fetchRelatedArticlesStart(state, action);
    case actionTypes.FETCH_RELATED_ARTICLES_SUCCESS: return fetchRelatedArticlesSuccess(state, action);
    case actionTypes.FETCH_RELATED_ARTICLES_FAIL: return fetchRelatedArticlesFail(state, action);
    case actionTypes.FECTH_ARTICLE_COMMENTS_SUCCESS: return fetchArticleCommentsSuccess(state, action);
    case actionTypes.FECTH_ARTICLE_COMMENTS_START: return fetchArticleCommenstStart(state, action);
    case actionTypes.FECTH_ARTICLE_COMMENTS_FAIL: return fetchArticleCommentsFail(state, action);
    case actionTypes.FETCH_PRODUCT_QUALIFICATIONS_SUCCESS: return fetchArticleCommentsSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_QUALIFICATIONS_START: return fetchArticleCommenstStart(state, action);
    case actionTypes.FETCH_PRODUCT_QUALIFICATIONS_FAIL: return fetchArticleCommentsFail(state, action);
    default: return state;
  }
};

export default reducer;