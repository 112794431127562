export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
      return true;
  }

  if (rules.required) {
      isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid
  }

  if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid
  }

  if (rules.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid
  }

  if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid
  }

  return isValid;
};

export const checkFormValidity = (inputsObject) => {
    if(!inputsObject) {
        return false;
    }
    return Object.keys(inputsObject)
        .reduce((valid, input) => inputsObject[input].valid && valid, true);
};

export const checkEqualValues = (val1, val2) => {
    return val1 === val2;
}

export const serializeInputValues = (inputsObject) => {
    return Object.keys(inputsObject).reduce((a, b) => {
        return {...a, [b]: inputsObject[b].value};
    }, {});
};

export const INVALID_EMAIL = 'Email no válido';
export const EMAIL_NOT_FOUND = 'Email no encontrado.';
export const INVALID_EMAIL_PASSWORD = 'Email o contraseña incorrectos.';
export const FILL_ALL_FIELDS = 'Por favor, complete los datos que correspondan.';
export const FIELDS_NOT_MATCH = 'Por favor, verifique que los campos coincidan.';
export const INVALID_RESET_TOKEN = 'Token no válido.';
export const ACCEPT_TERMS = 'Por favor, acepte los términos y condiciones.';
export const SOMETHING_WAS_WRONG = 'Algo salió mal. Por favor, intente más tarde.';
export const EMAIL_EXISTS = 'Email ingresado ya existe. Por favor, intente con otro.';
