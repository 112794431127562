import * as actionTypes from '../actions/actionTypes';

const initialState = {
  showShareModal: false,
  leftPosition: 0,
  topPosition: 0,
  url: '',
  title: ''
};

const showShareTooltip = (state, action) => {
  return {
    ...state,
    showShareModal : true,
    leftPosition: action.leftPosition,
    topPosition: action.topPosition,
    url: action.url,
    title: action.title
  };
};

const hideShareTooltip = (state, action) => {
  return {
    ...state,
    showShareModal: false
  };
};

const reducer = (state=initialState, action) => {
  switch(action.type) {
    case actionTypes.SHOW_SHARE_TOOLTIP: return showShareTooltip(state, action);
    case actionTypes.HIDE_SHARE_TOOLTIP: return hideShareTooltip(state, action);
    default: return state;
  }
};

export default reducer;
