import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './m-preheader.scss';
import iconGum from './logo_gum.png';
import iconGeo from './icon_geo.svg';
import iconSunstar from './logo_sunstar.png';
import NavSocial from './social/c-social';
import ToggleButton from './toggleButton/c-toggle-button';
import * as actions from '../../store/actions/index';

class Preheader extends Component {
  state = {
    active: false,
    solutions: [
      {
        id: 'cuidado-diario',
        label: 'Cuidado diario',
        link: '/categoria/soluciones/cuidado-diario'
      },
      {
        id: 'mal-aliento',
        label: 'Mal Aliento',
        link: '/categoria/soluciones/mal-aliento'
      },
      {
        id: 'cuidado-de-las-encias',
        label: 'Cuidado de las encías',
        link: '/categoria/soluciones/cuidado-de-las-encias'
      },
      {
        id: 'sensibilidad-dental',
        label: 'Sensibilidad dental',
        link: '/categoria/soluciones/sensibilidad-dental'
      },
      {
        id: 'ortodoncia',
        label: 'Ortodoncia',
        link: '/categoria/soluciones/ortodoncia'
      },
      {
        id: 'cuidado-infantil',
        label: 'Cuidado infantil',
        link: '/categoria/soluciones/cuidado-infantil'
      },
      {
        id: 'diabetes',
        label: 'Diabetes / Cuidado especial',
        link: '/categoria/soluciones/diabetes,cuidado-especial'
      },
      {
        id: 'soluciones-todos',
        label: 'Todos',
        link: '/categoria/soluciones'
      }
    ],
    products: [
      {
        id: 'cepillos',
        label: 'Cepillos',
        link: '/categoria/productos/cepillos'
      },
      {
        id: 'pastas-dentales',
        label: 'Pastas dentales',
        link: '/categoria/productos/pastas-dentales'
      },
      {
        id: 'limpieza-entre-los-dientes',
        label: 'Limpieza entre los dientes',
        link: '/categoria/productos/limpieza-entre-los-dientes'
      },
      {
        id: 'ortodoncia',
        label: 'Ortodoncia',
        link: '/categoria/productos/ortodoncia'
      },
      {
        id: 'cuidado-infantil',
        label: 'Cuidado infantil',
        link: '/categoria/productos/cuidado-infantil'
      },
      {
        id: 'productos-todos',
        label: 'Todos',
        link: '/categoria/productos'
      }
    ]
  };

  toogleMenu = () => {
    this.setState( prevState => {
     return { active: !prevState.active }
    });
  }

  render() {
    const activeMenu = ['preheader__menus'];
    if(this.state.active) {
      activeMenu.push('is-active');
    }

    const solutions = this.state.solutions.map((solution) => (
      <li key={solution.id}><Link to={solution.link} className="preheader__sublink">{solution.label}</Link></li>
    ));

    const products = this.state.products.map((product) => (
      <li key={product.id}><Link to={product.link} className="preheader__sublink">{product.label}</Link></li>
    ));

    return (
      <header className="preheader cf mw">

        <div className="preheader__container cf">
          <div className="preheader__logos">
            <Link to="/" className="preheader__sublogo" title="Sunstar">
              <img src={ iconSunstar } className="" alt="Logo Sunstar" width="117" height="19" loading="lazy" />
            </Link>
            <Link to="/" className="preheader__logo" title="G.U.M">
              <img src={ iconGum }className="preheader__logo-img" alt="Logo GUM" width="176" loading="lazy" />
            </Link>
          </div>

          <NavSocial
              view="show-mobile"
              hiddeSocialMobile="hm"
              registered={this.props.onRegistryClicked}
              loggedIn={this.props.onLoginClicked}
              loggedOut={this.props.onLogoutClicked}
              isAuthenticated={this.props.isAuthenticated}
              userName={this.props.userName} />

          <div className={activeMenu.join(' ')}>
          <div className="show-mobile-nav">
                <a href="/" onClick={this.props.onShopClicked} className="preheader__linkStores cf" title="Buscar Tienda" rel="noopener noreferrer" target="_blank">
                  Buscar Tienda<img src={ iconGeo } className="preheader__iconStores" alt="Buscar Tienda" width="40" height="40" /></a>
              </div>
            <nav className="preheader__menu cf">
              <ul className="preheader__menu-list">
                <li className="preheader__menu-item dropdown">
                  <Link to="/categoria/soluciones" className="preheader__link" title="Soluciones">Soluciones</Link>
                  <ul className="preheader__dropdown">
                    { solutions }
                  </ul>
                </li>
                <li className="preheader__menu-item dropdown">
                  <Link to="/productos" className="preheader__link" title="Productos">Productos</Link>
                  <ul className="preheader__dropdown">
                    { products }
                  </ul>
                </li>
                <li className="preheader__menu-item">
                  <a href="/" className="preheader__link" title="Contacto" onClick={this.props.onContactClicked}>Contacto</a>
                </li>
              </ul>
            </nav>
            <NavSocial
              view="show-desktop"
              registered={this.props.onRegistryClicked}
              loggedIn={this.props.onLoginClicked}
              loggedOut={this.props.onLogoutClicked}
              isAuthenticated={this.props.isAuthenticated}
              userName={this.props.userName}
              shopClicked={this.props.onShopClicked}  />
            </div>
            <ToggleButton active={this.state.active} openMenu={this.toogleMenu} />
          </div>
      </header>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.login.userLogged,
    userName: state.login.userName
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onContactClicked: e => dispatch(actions.toggleContact(e)),
    onShopClicked: e => dispatch(actions.showMap(e)),
    onRegistryClicked: e => dispatch(actions.toggleRegistry(e)),
    onLoginClicked: e => dispatch(actions.toggleInitialLogin(e)),
    onLogoutClicked: e => dispatch(actions.logout(e))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Preheader);