export const SELECT_TAG = 'SELECT_TAG';

export const HIDE_MAP = 'HIDE_MAP';
export const SHOW_MAP = 'SHOW_MAP';

export const TOGGLE_RETAIL = 'TOGGLE_RETAIL';
export const TOGGLE_INITIAL_LOGIN = 'TOGGLE_INITIAL_LOGIN';
export const TOGGLE_LOGIN = 'TOGGLE_LOGIN';
export const TOGGLE_REGISTRY = 'TOGGLE_REGISTRY';
export const TOGGLE_CONTACT = 'TOGGLE_CONTACT';
export const TOGGLE_CONTACT_SUCCESS = 'TOGGLE_CONTACT_SUCCESS';
export const TOGGLE_SUBSCRIPTION = 'TOGGLE_SUBSCRIPTION';
export const TOGGLE_FORGET_PASSWORD = 'TOGGLE_FORGET_PASSWORD';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const FETCH_POST_START = 'FETCH_POST_START';
export const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS';
export const FETCH_POST_FAIL = 'FETCH_POST_FAIL';

export const FETCH_RELATED_PRODUCTS_START = 'FETCH_RELATED_PRODUCTS_START';
export const FETCH_RELATED_PRODUCTS_SUCCESS = 'FETCH_RELATED_PRODUCTS_SUCCESS';
export const FETCH_RELATED_PRODUCTS_FAIL = 'FETCH_RELATED_PRODUCTS_FAIL';

export const FETCH_RELATED_ARTICLES_START = 'FETCH_RELATED_ARTICLES_START';
export const FETCH_RELATED_ARTICLES_SUCCESS = 'FETCH_RELATED_ARTICLES_SUCCESS';
export const FETCH_RELATED_ARTICLES_FAIL = 'FETCH_RELATED_ARTICLES_FAIL';

export const RESET_START = 'RESET_START';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';

export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS';
export const FETCH_BANNERS_FAIL = 'FETCH_BANNERS_FAIL';

export const SHOW_SHARE_TOOLTIP = 'SHOW_SHARE_TOOLTIP';
export const HIDE_SHARE_TOOLTIP = 'HIDE_SHARE_TOOLTIP';

export const FECTH_ARTICLE_COMMENTS_START = 'FECTH_ARTICLE_COMMENTS_START';
export const FECTH_ARTICLE_COMMENTS_SUCCESS = 'FECTH_ARTICLE_COMMENTS_SUCCESS';
export const FECTH_ARTICLE_COMMENTS_FAIL = 'FECTH_ARTICLE_COMMENTS_FAIL';

export const FETCH_PRODUCT_QUALIFICATIONS_START = 'FETCH_PRODUCT_QUALIFICATIONS_START';
export const FETCH_PRODUCT_QUALIFICATIONS_SUCCESS = 'FETCH_PRODUCT_QUALIFICATIONS_SUCCESS';
export const FETCH_PRODUCT_QUALIFICATIONS_FAIL = 'FETCH_PRODUCT_QUALIFICATIONS_FAIL';
