import React, { Component } from 'react';
import { connect } from 'react-redux';

import './m-modal-contact-form.scss';
import contactoImg from './contacto-producto2.png'
import * as actions from '../../store/actions/index';
import Input from '../ui/input/input';
import axios from '../../utils/axios';
import * as formUtils from '../../utils/formUtilities';
import Button from '../ui/button/button';

class ModalContactForm extends Component {
  state = {
    inputs: {
      name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Nombre',
          className: 'contact__input'
        },
        position: 'left',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      last_name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Apellido',
          className: 'contact__input'
        },
        position: 'left',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'Mail',
          className: 'contact__input'
        },
        position: 'left',
        relatedField: 'repeat_email',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      },
      repeat_email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'Repetir mail*',
          className: 'contact__input'
        },
        position: 'left',
        relatedField: 'email',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      },
      message: {
        elementType: 'textarea',
        elementConfig: {
          placeholder: 'Mensaje',
          className: 'contact__textarea'
        },
        position: 'right',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false
      },
    },
    errorMessage: '',
    formIsValid: true,
    formTouched: false,
    loading: false
  }

  inputChangeHandler = (event, inputIdentifier) => {
    let isValid = formUtils.checkValidity(event.target.value, this.state.inputs[inputIdentifier].validation);
    let errorMessage = '';

    const relatedField = this.state.inputs[inputIdentifier].relatedField;
    if(relatedField) {
      isValid = formUtils.checkEqualValues(event.target.value, this.state.inputs[relatedField].value) && isValid;
    }

    let updatedInputs = {
      ...this.state.inputs,
      [inputIdentifier]: {
        ...this.state.inputs[inputIdentifier],
        value: event.target.value,
        valid: isValid,
        touched: true
      }
    };

    if(relatedField) {
      updatedInputs = {
        ...updatedInputs,
        [relatedField]: {
          ...this.state.inputs[relatedField],
          valid: isValid
        }
      }
    }

    if(!isValid && this.state.inputs[inputIdentifier].validation.isEmail) {
      errorMessage = formUtils.INVALID_EMAIL;
    }
    if(relatedField && !formUtils.checkEqualValues(event.target.value, this.state.inputs[relatedField].value)) {
      errorMessage = formUtils.FIELDS_NOT_MATCH;
    }

    let formIsValid = this.state.formIsValid;
    if(this.state.formTouched) {
      formIsValid = formUtils.checkFormValidity(updatedInputs);
    }

    this.setState({
      inputs: updatedInputs,
      formIsValid: formIsValid,
      errorMessage: errorMessage
    });
  }

  onSubmitHandler = e => {
    e.preventDefault();
    const formIsValid = formUtils.checkFormValidity(this.state.inputs);

    if(formIsValid) {
      axios.post('/gum/v1/contacts', formUtils.serializeInputValues(this.state.inputs))
      .then(res => {
        this.props.onSuccess();
        this.setState({loading:false});
      })
      .catch(err => {
        this.props.onSuccess();
        this.setState({loading:false});
      });
    }
    this.setState({formIsValid: formIsValid, formTouched: true, loading: formIsValid});
  }

  render() {
    const groupInputs = Object.keys(this.state.inputs).reduce((a,b) => {
      const inputObject = {...this.state.inputs[b], key:b}
      a[this.state.inputs[b].position] =  (a[this.state.inputs[b].position] || [])
        .concat(inputObject);
      return a;
    }, {});

    const formInputs = Object.keys(groupInputs).map(position => {
      const inputs = groupInputs[position].map(input => {
        const classes = input.elementConfig.className ? [input.elementConfig.className] : [];
        if(!input.valid && this.state.formTouched) {
          classes.push('invalid');
        }
        const config = {...input.elementConfig, className: classes.join(' ')};
        return <Input key={input.key}
          type={input.elementType}
          config={config}
          changed={e => this.inputChangeHandler(e, input.key)}
          value={this.state.inputs[input.key].value} />
      });
      return (
        <div className="contact__col" key={position}>
          {inputs}
        </div>
      );
    });

    let erroMessage = null;
    if(!this.state.formIsValid) {
      erroMessage = <div className="contact__error">{this.state.errorMessage || formUtils.FILL_ALL_FIELDS }</div>;
    }

    return (
      <div className="contact cf">
        <div className="contact__img">
          <img src={contactoImg} alt="Pasta dental y cepillo de dientes"/>
        </div>

        <div className="contact__form">
          <p className="contact__title">Dejanos tu consulta y te contestamos lo antes posible</p>
          <form className="cf" onSubmit={this.onSubmitHandler} noValidate>
            { formInputs }
            <Button config={{className:"contact__btn"}} loading={this.state.loading}>Enviar</Button>
            { erroMessage }
          </form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSuccess: (e) => dispatch(actions.toggleContactSuccess(e, 'Recibimos tu mensaje, te contestamos por e-mail.'))
  };
};

export default connect(null, mapDispatchToProps)(ModalContactForm);