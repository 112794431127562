import React, { Component } from 'react';
import './m-modal-retails.scss';
import logoJumbo        from './logo-jumbo.png';
import logoDisco        from './logo-disco.png';
import logoVea        from './logo-vea.png';
import logoSelma        from './logo-selma.png';
import logoCoto         from './logo-coto.png';
import logoFarmacity    from './logo-farmacity.png';
import logoFarmaciaUomax    from './logo-uomax.png';
import logoCarrefour    from './logo-carrefour.png';
import logoVassallo     from './logo-vassallo.png';
import logoMercadoLibre from './logo-mercado-libre.png';
import logoWalmart      from './logo-walmart.png';
import logoCentralOeste from './logo-centraloeste.png';
import logoSoy      from './logo-soy.png';
import logoLibertad      from './logo-libertad.png';
import logoLider from './logo-lider.png';
import logoRed      from './logo-red.png';

class ModalRetails extends Component {
  render() {
    return (
      <div className="retails">
        <p className="retails__title">Elegí tu tienda más cercana</p>

        <div className="cf">
          <div className="retails__logo">
            <a id="MercadoLibre" href="https://tienda.mercadolibre.com.ar/gum" target="_blank" rel="noreferrer noopener">
              <img src={logoMercadoLibre} alt="Mercado Libre" />
            </a>
          </div>
          <div className="retails__logo">
            <a id="Farmacity" href="https://www.farmacity.com/gum" target="_blank" rel="noreferrer noopener">
              <img src={logoFarmacity} alt="Farmacity" />
            </a>
          </div>
    
          <div className="retails__logo">
            <a id="Central Oeste" href="https://www.centraloeste.com.ar/cuidado-bucal.html?brand=Gum" target="_blank" rel="noreferrer noopener">
              <img src={logoCentralOeste} alt="Central Oeste" />
            </a>
          </div>
          <div className="retails__logo">
            <a id="Vassallo" href="https://www.vassallo.com.ar/29-cuidado-oral/s-1/marca-gum/categorias_2-cuidado_oral" target="_blank" rel="noreferrer noopener">
              <img src={logoVassallo} alt="Vassallo" />
            </a>
          </div>
          <div className="retails__logo">
            <a id="Jumbo" href="https://www.jumbo.com.ar/busca/?ft=gum" target="_blank" rel="noreferrer noopener">
              <img src={logoJumbo} alt="Jumbo" />
            </a>
          </div>
          <div className="retails__logo">
            <a id="Disco" href=" https://www.disco.com.ar/Comprar/Home.aspx#_atCategory=false&_atGrilla=true&_query=gum" target="_blank" rel="noreferrer noopener">
              <img src={logoDisco} alt="Disco" />
            </a>
          </div>
          <div className="retails__logo">
            <a id="Vea" href="https://www.veadigital.com.ar/Comprar/Home.aspx#_atCategory=false&_atGrilla=true&_query=gum" target="_blank" rel="noreferrer noopener">
              <img src={logoVea} alt="Vea" />
            </a>
          </div>
          <div className="retails__logo">
            <a id="Walmart" href="https://www.walmart.com.ar/busca?ft=gum" target="_blank" rel="noreferrer noopener">
              <img src={logoWalmart} alt="Walmart" />
            </a>
          </div>
          <div className="retails__logo">
            <a id="Selma" href="https://selmadigital.com/marcas/gum/" target="_blank" rel="noreferrer noopener">
              <img src={logoSelma} alt="Selma" />
            </a>
          </div>
          <div className="retails__logo">
            <a id="Coto" href="https://www.cotodigital3.com.ar/sitios/cdigi/browse/catalogo-perfumer%C3%ADa-cuidado-bucal/_/N-iak9svZi2vq14Z1dgg3ts?Nf=product.endDate%7CGTEQ+1.5851808E12%7C%7Cproduct.startDate%7CLTEQ+1.5851808E12&Nr=AND%28product.sDisp_200%3A1004%2Cproduct.language%3Aespa%C3%B1ol%2COR%28product.siteId%3ACotoDigital%29%29&showMoreIds=4291659268" target="_blank" rel="noreferrer noopener">
              <img src={logoCoto} alt="Coto" />
            </a>
          </div>
          <div className="retails__logo">
            <a id="Soy" href="https://www.soytufarmacia.net/shop?brand=gum" target="_blank" rel="noreferrer noopener">
              <img src={logoSoy} alt="Soy" />
            </a>
          </div>
          <div className="retails__logo">
            <a id="Carrefour" href="https://supermercado.carrefour.com.ar/perfumeria/cuidado-dental.html?ec_marca=6947&utm_source=web-crf&utm_medium=referral&utm_content=gum&utm_campaign=BuscadorInterno" target="_blank" rel="noreferrer noopener">
              <img src={logoCarrefour} alt="Carrefour" />
            </a>
          </div>         
          <div className="retails__logo">
            <a id="Farmacias Uomax" href="https://www.uomax.com.ar/busqueda_avanzada.php?buscar=1&palabra=gum" target="_blank" rel="noreferrer noopener">
              <img src={logoFarmaciaUomax} alt="Farmacias Uomax" />
            </a>
          </div>
          <div className="retails__logo">
            <a id="Farmacias Libertad" href="https://www.hiperlibertad.com.ar/perfumeria/cuidado-oral?O=OrderByTopSaleDESC&FQ=B:2001263&ft&pageNumber=1" target="_blank" rel="noreferrer noopener">
              <img src={logoLibertad} alt="Farmacia Libertad" />
            </a>
          </div> 
          <div className="retails__logo">
            <a id="Farmacias Red" href="https://redgeneralpaz.com/#!/categoria/0/pagina/0/?filtro=GUM" target="_blank" rel="noreferrer noopener">
              <img src={logoRed} alt="Farmacias Red" />
            </a>
          </div> 
          <div className="retails__logo">
            <a id="Farmacias Lider" href="https://farmaciaslider.com.ar/39-cepillos-dentales-?q=Marca-G.U.M." target="_blank" rel="noreferrer noopener">
              <img src={logoLider} alt="Farmacias Lider" />
            </a>
          </div>           
        </div>
      </div>
    );
  }
}

export default ModalRetails;
