import React from 'react';

import Preheader from '../modulePreheader/m-preheader';
import Footer from '../moduleFooter/m-footer';
import Modals from '../../containers/modals/modals';
import Share from '../moduleShare/m-share';

const Layout = (props) => (
  <>
    <Preheader />
    {props.children}
    <Footer />
    <Modals />
    <Share />
  </>
);

export default Layout;