import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import axios, { CancelToken } from '../../utils/axios';

import './m-map.scss'
import Spinner from '../ui/spinner/spinner';
import NavMap from './nav/c-navMap';
import * as utils from '../../utils/utilities';

class MapModule extends Component {
  signal = CancelToken.source();

  state = {
    locations: [],
    locationCenter: {
      lat: -34.0000000,
      lng: -64.0000000
    },
    markers: [],
    mapZoom: 5,
    isLoaded: false,
    isLoading: false,
    isSearching: false,
    showingInfoWindow: false,
    activeMarker: null,
    selectedPlace: {
      title: '',
      address: ''
    },
    searchValue: '',
    haveDistances: false
  }

  componentDidMount() {
    this.onLoadPharmacies();

    this.onGetCurrentPosition();
  }

  onLoadPharmacies = async () => {
    try {
      const res = await axios.get('/gum/v1/pharmacies', {
        cancelToken: this.signal.token
      });
      const locations = res.data;
      const markers = locations.map(marker =>(
        <Marker
          id={marker.id}
          key={marker.id}
          name={marker.name}
          address={marker.address}
          city={marker.city}
          phone={marker.phone}
          position={{lat:marker.location.lat, lng: marker.location.lng}}
          onClick={this.markerClickHandler} />
      ));

      this.setState({
        locations: locations,
        isLoaded: true,
        markers: markers
      });
    } catch(err) {
      console.warn('Error: ', err.message);
    }
  }

  onGetCurrentPosition = () => {
    const options = {
      enableHighAccuracy: true,
      maximumAge: 1
    };

    if(navigator.geolocation) {
      this.setState({isLoading:true});
      navigator.geolocation.getCurrentPosition(
        pos => {
          const crd = pos.coords;
          this.setState({
            locationCenter:{
              lat: crd.latitude,
              lng: crd.longitude
            },
            mapZoom: 14,
            isLoading: false,
            searchValue: '',
            haveDistances: true
          });
          this.getDistances();
        },
        err => {
          console.warn(`Error(${err.code}): ${err.message}`);
          this.setState({isLoading:false});
        },
        options
      );
    }
  }

  onChangeSearchHandler = (e) => {
    this.setState({searchValue: e.target.value});
  }

  onSearchHandler = (e) => {
    e.preventDefault();
    const address = encodeURIComponent(this.state.searchValue).replace(/%20/g, "+");
    this.setState({isSearching: true});
    axios.get(`gum/v1/pharmacies/address?address=${address}`).then(({data}) => {
        let localState = {isSearching: false};
        const {status, location} = data;
        if(status === "OK") {
          localState = {
            ...localState,
            locationCenter: location[0],
            haveDistances: true,
            mapZoom: 14
          };
        }
        this.setState(localState);
        this.getDistances();
      }).catch(err => {
        console.error(err);
        this.setState({isSearching: false});
      });
  }

  getDistances = () => {
    const {locationCenter} = this.state;
    const locations = this.state.locations.map(location => {
      return {
        ...location,
        distance: utils.getDistanceFromLatLonInKm(
          locationCenter.lat,
          locationCenter.lng,
          location.location.lat,
          location.location.lng
        )
      };
    });
    this.setState({locations: locations});
  };

  markerClickHandler = (props, marker, e) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
      locationCenter: props.position
    });
    console.log(props, marker);
  }

  mapClickHandler = () => {
    if(this.state.showingInfoWindow) {
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
    }
  }

  navItemClickHandler = (e, item) => {
    e.preventDefault();
    this.setState({
      locationCenter: item.location,
      mapZoom: 16,
      showingInfoWindow: false
    });
  }

  render() {
    const {
      activeMarker,
      haveDistances,
      isLoaded,
      isLoading,
      isSearching,
      locations,
      locationCenter,
      mapZoom,
      markers,
      selectedPlace,
      searchValue,
      showingInfoWindow
    } = this.state;

    const {address, city, name, phone} = selectedPlace;

    let locationsNav = locations;

    if(haveDistances) {
      locationsNav = locations.sort((a, b) => a.distance - b.distance);
    }

    let map = (
      <Map
        google={this.props.google}
        fullscreenControl={false}
        initialCenter={locationCenter}
        center={locationCenter}
        zoom={mapZoom}
        onClick={this.mapClickHandler} >
        {markers}
        <InfoWindow
          visible={showingInfoWindow}
          marker={activeMarker} >
            <div>
              <h1>{name}</h1>
              <p>{address}</p>
              <p>{city}</p>
              <p>Telefono: {phone}</p>
            </div>
        </InfoWindow>
      </Map>);

    if(isLoading) {
      map = <Spinner />;
    }

    if(isLoaded) {
      return (
        <div className="map">
          <div className="map__inner">
            {map}
          </div>
          <NavMap
            clicked={this.navItemClickHandler}
            isSearching={isSearching}
            locations={locationsNav}
            onCurrentPosition={this.onGetCurrentPosition}
            searchValue={searchValue}
            searchChanged={this.onChangeSearchHandler}
            submited={this.onSearchHandler} />
        </div>
      );
    }
    return <Spinner />;
  }
}

export default GoogleApiWrapper({apiKey: process.env.REACT_APP_GOOGLE_MAPS_API})(MapModule);
