import * as actionTypes from './actionTypes';
import axios from '../../utils/axios';
import * as actions from './index';

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START
  };
};

export const loginSuccess = (token, name) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('uName', name);
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token: token,
    userName: name
  };
};

export const loginFail = (error) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    errorCode: error
  };
};

export const logout = (e) => {
  if(e) {
    e.preventDefault();
  }
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('uName');
  return {
    type: actionTypes.LOGOUT
  };
};

export const login = (email, password) => {
  return dispatch => {
    dispatch(loginStart());
    axios.post('jwt-auth/v1/token', {username: email, password:password})
      .then(res => {
        const {token, user_display_name} = res.data;
        dispatch(loginSuccess(token, user_display_name));
        dispatch(actions.toggleLogin());
      })
      .catch(err => {
        dispatch(loginFail(err));
      });
  };
};

export const googleLogin = (idToken) => {
  return dispatch => {
    dispatch(loginStart());
    axios.post('/gum/v1/account/gconnect', {id_token: idToken})
      .then(res => {
        const {token, user_display_name} = res.data;
        dispatch(loginSuccess(token, user_display_name));
        dispatch(actions.toggleInitialLogin());
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('uName', user_display_name);
      })
      .catch(err => {
        dispatch(loginFail(err));
      });
  };
};

export const facebookLogin = (payload) => {
  return dispatch => {
    dispatch(loginStart());
    axios.post('/gum/v1/account/fbconnect', payload)
      .then(res => {
        const {token, user_display_name} = res.data;
        dispatch(loginSuccess(token, user_display_name));
        dispatch(actions.toggleInitialLogin());
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('uName', user_display_name);
      })
      .catch(err => {
        dispatch(loginFail(err));
      });
  };
};

export const resetPassword = (token) => {
  return dispatch => {
    dispatch(resetStart(token));
    dispatch(actions.toggleForgetPassword());
  };
};

export const resetStart = (token) => {
  return {
    type: actionTypes.RESET_START,
    token: token
  };
};
