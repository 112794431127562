import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  userLogged: false,
  email: '',
  token: '',
  userName: '',
  errorCode: null,
  resetPassword: false,
  resetToken: null
};

const loginStart = (state, action) => {
  return {
    ...state,
    errorCode: null,
    loading: true
  };
};

const loginSuccess = (state, action) => {
  return {
    ...state,
    email: action.email,
    token: action.token,
    userName: action.userName,
    userLogged: true,
    loading: false
  };
};

const loginFail = (state, action) => {
  return {
    ...state,
    errorCode: action.errorCode,
    loading: false
  };
};

const resetStart = (state, action) => {
  return {
    ...state,
    resetPassword: true,
    resetToken: action.token
  };
};

const logout = (state, action) => {
  return {
    ...state,
    email: '',
    token: '',
    userName: '',
    userLogged: false
  };
};

const reducer = (state=initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_START: return loginStart(state, action);
    case actionTypes.LOGIN_SUCCESS: return loginSuccess(state, action);
    case actionTypes.LOGIN_FAIL: return loginFail(state, action);
    case actionTypes.RESET_START: return resetStart(state, action);
    case actionTypes.LOGOUT: return logout(state, action);
    default: return state;
  }
}

export default reducer;