import React from 'react';
import { connect } from 'react-redux';

import ShopIcon from './ico_buy.svg';
import './c-shop-button.scss';
import * as actions from '../../../store/actions/index';

const shopButton = (props) => {
  const label = props.hasLabel ? <span>Comprar</span> : '';

  return (
    <a className="product__button" href="/" rel="noopener noreferrer" onClick={props.onShopSelected}><img src={ ShopIcon } alt="Comprar"/>{ label }</a>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onShopSelected: e => dispatch(actions.toggleRetails(e))
  };
};

export default connect(null, mapDispatchToProps)(shopButton);