import React from 'react';

import './c-tooltip-modal.scss';

const TooltipModal = (props) => (
  <div className="tooltip__modal">
    <span className="tooltip__title">Compartir:</span>

    <div className="tooltip__items cf">
      <div className="tooltip__item">
        <a href={`https://www.facebook.com/sharer.php?u=${props.url}`} target="_blank" rel="noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className="tooltip__icon">
            <path fill="#4665a4" d="M19.8 0C8.7.1-.1 9.2 0 20.2S9.2 40.1 20.2 40c11-.1 19.8-9 19.8-20C39.9 8.9 30.9-.1 19.8 0zm5.5 12.2h-2.9c-.6 0-1.1.4-1.2 1V16.1h4.1l-.5 4.6h-3.6v13.1h-5.5v-13h-2.6v-4.6h2.6v-3c0-2.1 1-5.5 5.5-5.5h4.1v4.5z"/>
          </svg>
          <span className="tooltip__icon-title">Facebook</span>
        </a>
      </div>

      <div className="tooltip__item">
        <a href={`https://twitter.com/intent/tweet?url=${props.url}&text=${props.title}`} target="_blank" rel="noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className="tooltip__icon">
            <path fill="#3bb1dd" d="M19.8 0C8.7.1-.1 9.2 0 20.2S9.2 40.1 20.2 40c11-.1 19.8-9 19.8-20C39.9 8.9 30.9-.1 19.8 0zm9.9 16.8v.6c0 7.5-6.1 13.6-13.6 13.6h-.2c-2.6 0-5.2-.7-7.4-2.2 2.6.3 5.1-.4 7.2-2-2.1 0-3.9-1.4-4.5-3.3.3.1.6.1.9.1.4 0 .9-.1 1.3-.2-2.2-.4-3.9-2.4-3.9-4.7v-.1c.7.4 1.4.6 2.2.6-1.3-.9-2.1-2.4-2.2-4 0-.8.2-1.7.7-2.4 2.5 3 6.1 4.8 10 5-.1-.4-.1-.7-.1-1.1 0-2.7 2.2-4.8 4.8-4.8 1.3 0 2.6.5 3.5 1.5 1.1-.2 2.1-.6 3.1-1.2-.4 1.1-1.1 2.1-2.1 2.6 1-.1 1.9-.4 2.8-.8-.7 1.3-1.5 2.2-2.5 2.8z"/>
          </svg>
          <span className="tooltip__icon-title">Twitter</span>
        </a>
      </div>

{/*       <div className="tooltip__item">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className="tooltip__icon">
          <path fill="#d93175" d="M25.8 10.6H13.7c-1.9 0-3.5 1.5-3.5 3.5V26c0 1.9 1.6 3.5 3.5 3.5h12.1c1.9 0 3.5-1.5 3.5-3.5V14.1c0-1.9-1.6-3.5-3.5-3.5zm-6 15.6c-3.4 0-6.2-2.9-6.1-6.3s2.9-6.2 6.3-6.1c3.4 0 6.1 2.8 6.1 6.2-.1 3.5-2.9 6.3-6.3 6.2zm6.4-11.1c-.8 0-1.5-.7-1.4-1.5 0-.8.7-1.5 1.5-1.4.8 0 1.4.7 1.4 1.5s-.7 1.4-1.5 1.4zm-6.4 1.4c-2 0-3.6 1.6-3.5 3.6s1.6 3.6 3.6 3.5c2 0 3.5-1.6 3.5-3.6 0-1.9-1.7-3.5-3.6-3.5zm0-16.5C8.7.1-.1 9.2 0 20.2S9.2 40.1 20.2 40c11-.1 19.8-9.1 19.8-20C39.9 8.9 30.9-.1 19.8 0zm12.1 26c0 3.4-2.8 6.1-6.2 6.1h-12c-3.4 0-6.1-2.7-6.1-6.1V14.1c0-3.4 2.8-6.1 6.1-6.1h12.1c3.4 0 6.1 2.7 6.2 6.1-.1 0-.1 11.9-.1 11.9z"/>
        </svg>
        <span className="tooltip__icon-title">Instagram</span>
      </div>
 */}
      <div className="tooltip__item">
        <a href={'https://'+(props.isMobile ? 'api':'web')+`.whatsapp.com/send?text=${props.title}%20${props.url}`} target="_blank" rel="noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className="tooltip__icon">
            <circle cx="20" cy="20" r="20" fill="#25d366"/>
            <path fill="#ffffff" d="M6 34.1l2-7.2c-1.2-2.1-1.9-4.5-1.9-7C6.1 12.2 12.4 6 20 6c3.7 0 7.2 1.5 9.9 4.1 2.6 2.6 4.1 6.1 4.1 9.9 0 7.7-6.3 13.9-13.9 13.9-2.3 0-4.6-.6-6.7-1.7L6 34.1zm7.7-4.5l.4.3c1.8 1.1 3.8 1.6 5.9 1.6 6.4 0 11.6-5.2 11.6-11.6 0-3.1-1.2-6-3.4-8.2C26 9.5 23.1 8.3 20 8.3c-6.4 0-11.6 5.2-11.6 11.6 0 2.2.6 4.3 1.8 6.2l.3.4-1.2 4.3 4.4-1.2z"/>
            <path fill="#ffffff" d="M27.1 23.2c-.1-.1-.3-.2-.7-.4-.3-.2-2.1-1-2.4-1.1-.3-.1-.6-.2-.8.2-.2.3-.9 1.1-1.1 1.4-.2.2-.4.3-.8.1-.3-.2-1.5-.5-2.8-1.7-1-.9-1.7-2.1-1.9-2.4-.2-.3 0-.5.2-.7.2-.2.3-.4.5-.6.2-.2.2-.3.3-.6.1-.2.1-.4 0-.6-.1-.2-.8-1.9-1.1-2.6-.3-.7-.6-.6-.8-.6H15c-.2 0-.6.1-.9.4-.3.3-1.2 1.2-1.2 2.9s1.2 3.4 1.4 3.6c.2.2 2.5 3.8 6 5.3.8.4 1.5.6 2 .7.8.3 1.6.2 2.2.1.7-.1 2.1-.8 2.4-1.7.3-.9.3-1.6.2-1.7z"/>
          </svg>
          <span className="tooltip__icon-title">WhatsApp</span>
        </a>
      </div>

      <div className="tooltip__item">
        <a href='/' onClick={(e)=>props.copied(e, props.url)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className="tooltip__icon">
            <path fill="#6bb895" d="M20-.01a20 20 0 1 0 20 20 20.007 20.007 0 0 0-20-20zm6.73 24.49a6.73 6.73 0 1 1-13.46 0v-3.36h2.13v3.36a4.6 4.6 0 0 0 9.2 0v-3.36h2.13v3.36zm-7.85-.01v-8.96a1.12 1.12 0 0 1 2.24 0v8.97A1.116 1.116 0 0 1 20 25.59a1.129 1.129 0 0 1-1.12-1.11v-.01zm7.85-5.6H24.6v-3.36a4.6 4.6 0 0 0-9.2 0v3.36h-2.13v-3.36a6.73 6.73 0 1 1 13.46 0v3.36z"/>
          </svg>
          <span className="tooltip__icon-title">Copiar</span>
        </a>
      </div>

      <div className="tooltip__item">
        <a href={`mailto:?to=&body=${props.title}+${props.url}&subject=G.U.M.+Argentina`} target="_blank" rel="noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" className="tooltip__icon">
            <path fill="#2f6758" d="M26.91 14.25H13.1L20 20.1zm-6.31 7.9h-1.2a.778.778 0 0 1-.52-.19l-7.1-5.99v9.49a.348.348 0 0 0 .37.28h15.7a.341.341 0 0 0 .37-.28v-9.49l-7.1 5.99a.76.76 0 0 1-.52.19zM20-.01a20 20 0 1 0 20 20 20.007 20.007 0 0 0-20-20zm10.29 25.47a2.393 2.393 0 0 1-2.44 2.35h-15.7a2.4 2.4 0 0 1-2.44-2.35V14.52a2.4 2.4 0 0 1 2.44-2.34h15.7a2.392 2.392 0 0 1 2.44 2.34v10.94z"/>
          </svg>
          <span className="tooltip__icon-title">Mail</span>
        </a>
      </div>
    </div>
  </div>
);

export default TooltipModal;