import React from 'react';
import './spinner.scss';

const spinner = (props) => (
<div className="spinner" {...props}>
  <span className="spinner-inner-1"></span>
  <span className="spinner-inner-2"></span>
  <span className="spinner-inner-3"></span>
</div>
);

export default spinner;