export const getUrlParameters = (query) => {
  const url = query.substring(1);
  const params = url.split('&').reduce((obj, item) => {
    const paramName = item.split('=');
    if(paramName[0] && paramName[1]) {
      obj[paramName[0]] = paramName[1];
    }
    return obj;
  }, {});
  return params;
}

export const postType = {
  products: 'productos',
  articles: 'articulos'
};

export const breakpoints = {
  LG: 1365,
  MD: 1024,
  SM: 768,
  XS: 479
};

export const breakpointsColumns = {
  LG: 4,
  MD: 3,
  SM: 2,
  XS: 1
};

export const getBreakpointColumns = (width) => {
  const keys = Object.keys(breakpoints);
  for(var key of keys) {
    if(width >= breakpoints[key]) {
      return breakpointsColumns[key];
    }
  }
  return breakpointsColumns.XS;
};

export const objectIsEmpty = (obj) => {
  return Object.getOwnPropertyNames(obj).length === 0;
}

export const getScoreDescription = (score) => {
  const descriptions = {
    1: 'Malo',
    2: 'Regular',
    3: "Bueno",
    4: 'Muy Bueno',
    5: 'Excelente'
  };
  return descriptions[score] || null;
};

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

export const getDistanceFromLatLonInKm = (lat1,lon1,lat2,lon2) => {
  const R = 6371;
  const dLat = deg2rad(lat2-lat1);
  const dLon = deg2rad(lon2-lon1);
  const a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const d = R * c;
  return d;
}
