import React from 'react';

const Pharmacy = (
  {
    address,
    city,
    distance,
    name,
    phone
  }) => (
  <>
    <div className="map__listDetails">
      <h3 className="map__locationTitle">{name}</h3>
      <p className="map__locationDetail">{address}</p>
      <p className="map__locationDetail">{city}</p>
      <p className="map__locationPhone">Telefono: {phone}</p>
    </div>
    {
      distance ?
      <div className="map__listDistance">
        <p>{distance < 1 ? Math.round(distance * 1000)+" metros" : Math.round(distance)+" Kilometros"}</p>
      </div>
      : ""
    }
  </>
);

export default Pharmacy;