import React, { Component } from 'react';
import { connect } from 'react-redux';

import './m-modal-forget-password.scss';
import handImg from './hand-gum.png';
import Input from '../ui/input/input';
import * as formUtils from '../../utils/formUtilities';
import * as actions from '../../store/actions/index';
import axios from '../../utils/axios';
import Button from '../ui/button/button';

class ModalForgetPassword extends Component {
  state = {
    forgetInputs: {
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'E-mail',
          className: 'rounded'
        },
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      }
    },
    resetInputs: {
      password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Contraseña',
          autoComplete: 'reset-password'
        },
        value: '',
        validation: {
          required: true
        },
        relatedField: 'repeat_password',
        valid: false,
        touched: false
      },
      repeat_password: {
        elementType: 'input',
        elementConfig: {
          type: 'password',
          placeholder: 'Repetir contraseña',
          autoComplete: 'reset-password'
        },
        value: '',
        validation: {
          required: true
        },
        relatedField: 'password',
        valid: false,
        touched: false
      }
    },
    errorMessage: '',
    formIsValid: true,
    formTouched: false,
    loading: false
  }

  inputChangeHandler = (event, inputIdentifier) => {
    const modalType = !this.props.resetPassword ? 'forgetInputs' : 'resetInputs';
    const inputsType = this.state[modalType];

    let isValid = formUtils.checkValidity(event.target.value, inputsType[inputIdentifier].validation);

    const relatedField = inputsType[inputIdentifier].relatedField;

    let equalRelatedValues = true;
    if(relatedField) {
      equalRelatedValues = formUtils.checkEqualValues(event.target.value, inputsType[relatedField].value);
      isValid = equalRelatedValues && isValid;
    }

    let updatedInputs = {
      ...inputsType,
      [inputIdentifier]: {
        ...inputsType[inputIdentifier],
        value: event.target.value,
        valid: isValid,
        touched: true
      }
    };

    if(relatedField) {
      updatedInputs = {
        ...updatedInputs,
        [relatedField]: {
          ...inputsType[relatedField],
          valid: isValid
        }
      }
    }

    let formIsValid = this.state.formIsValid;
    if(this.state.formTouched) {
      formIsValid = formUtils.checkFormValidity(updatedInputs);
    }

    let errorMessage = '';
    if(relatedField && !equalRelatedValues) {
      errorMessage = formUtils.FIELDS_NOT_MATCH;
    }

    this.setState({
      [modalType]: updatedInputs,
      formIsValid: formIsValid,
      errorMessage: errorMessage
    });
  }

  submitHandler = (e) => {
    e.preventDefault();
    const inputsType = !this.props.resetPassword ? this.state.forgetInputs : this.state.resetInputs;
    const formIsValid = formUtils.checkFormValidity(inputsType);

    if(formIsValid) {
      const url = !this.props.resetPassword ? '/gum/v1/account/forgot-password' : '/gum/v1/account/reset-password';

      let data = formUtils.serializeInputValues(inputsType);
      if(this.props.resetPassword) {
        data = {
          ...data,
          token: this.props.resetToken
        }
      }

      axios.post(url, data)
      .then(res => {
        const successMessage = !this.props.resetPassword
          ? 'Se ha enviado un link a su correo.'
          : 'Se ha reestablecido su contraseña.';
        this.props.onSuccess();
        this.props.showMessage(successMessage);
        this.setState({loading:false});
      })
      .catch(err => {
        const errorMessage = !this.props.resetPassword
          ? formUtils.EMAIL_NOT_FOUND
          : formUtils.INVALID_RESET_TOKEN;
        this.setState({loading:false, errorMessage: errorMessage, formIsValid: false});
      });
    }
    this.setState({formIsValid: formIsValid, formTouched: true, loading: formIsValid});
  }

  render() {
    const inputsType = !this.props.resetPassword ? this.state.forgetInputs : this.state.resetInputs;

    const inputs = Object.keys(inputsType).map(input => {
      const classes = inputsType[input].elementConfig.className
        ? [inputsType[input].elementConfig.className] : [];

      if(!inputsType[input].valid && this.state.formTouched) {
        classes.push('invalid');
      }

      const config = {...inputsType[input].elementConfig, className: classes.join(' ')};

      return <Input key={input}
        type={inputsType[input].elementType}
        config={config}
        changed={e => this.inputChangeHandler(e, input)}
        value={inputsType[input].value} />;

    });

    let errorMessage = null;
    if(!this.state.formIsValid) {
      errorMessage = <div className="login__error">{this.state.errorMessage || formUtils.FILL_ALL_FIELDS}</div>;
    }

    const buttonClasses = ['submit'];
    if(this.props.resetPassword) {
      buttonClasses.push('reset');
    }

    return (
      <div>
        <div className="forget login cf">
          <div className="login__img">
            <img src={handImg} alt="" />
          </div>

          <div className="login__form">
            <p className="login__title">¿Olvidaste tu contraseña?</p>
            <form onSubmit={this.submitHandler} noValidate>
              {inputs}
              <Button
                config={{className: buttonClasses.join(' ')}}
                loading={this.state.loading}>
                  {!this.props.resetPassword ? 'Recuperar contraseña' : 'Reestablecer contraseña'}
              </Button>
              {errorMessage}
            </form>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    resetPassword: state.login.resetPassword,
    resetToken: state.login.resetToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSuccess: () => dispatch(actions.toggleForgetPassword()),
    showMessage: (message) => dispatch(actions.toggleContactSuccess(null, message))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalForgetPassword);